import { useState, useEffect } from 'react';
import moment from 'moment';

import { Modal } from 'flowbite-react';
import { CommonButton } from '../../atoms/CommonButton';
import { InputField } from '../../atoms/InputField';
import { DatePickerField } from '../../atoms/DatePickerField';

import { formatDatePickerDate } from '../../../utils/commonFunctions';

export const DownloadERAModal = ({ openDownloadERA, setOpenDownloadERA, onSubmit }: any) => {
  const [formData, setFormData] = useState<any>({ checkNumber: null, receivedDate: null });
  const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false);

  useEffect(() => {
    const hasValidInput = Object.values(formData).some((value) => !!value);
    setIsSubmitEnabled(hasValidInput);
  }, [formData]);

  const onChangeFields = (event: any) => {
    setFormData((prev: any) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const onChangeDate = (date: any, name: string) => {
    setFormData((prev: any) => ({ ...prev, [name]: moment(date).format('YYYY-MM-DD') }));
  };

  return (
    <Modal show={openDownloadERA} onClose={() => setOpenDownloadERA(false)}>
      <Modal.Header>Download ERA</Modal.Header>
      <Modal.Body>
        <div className="grid md:grid-cols-2 gap-3 mt-6">
          <InputField label="Check Number" name="checkNumber" onChange={onChangeFields} value={formData?.checkNumber} />
          <DatePickerField
            label="Received Date"
            selectedDate={formatDatePickerDate(formData?.receivedDate)}
            onChange={(date: Date | null) => {
              if (date) {
                onChangeDate(date, 'receivedDate');
              }
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="grid md:grid-cols-2">
        <div className="col-span-1"></div>
        <div className="col-span-1 grid md:grid-cols-2 gap-2">
          <CommonButton
            type="button"
            label={'Close'}
            buttonType="secondary"
            onClick={() => {
              setOpenDownloadERA(false);
              setFormData({});
            }}
          />
          <CommonButton
            type="button"
            label={'Submit'}
            buttonType="primary"
            onClick={() => onSubmit(formData)}
            disabled={!isSubmitEnabled}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
