import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  PostClaimEraPaymentInterface,
  PostClaimEraPaymentInitialInterface,
} from '../../../../interfaces/paymentInterfaces';
import { postClaimEraPaymentAPIRequest } from '../../../../api/payments/claimEraAutoPost';

const initialState: PostClaimEraPaymentInitialInterface = {
  postClaimEraPaymentLoading: false,
  postClaimEraPaymentData: {},
  postClaimEraPaymentError: null,
  postClaimEraPaymentStatus: 'IDLE',
};

export const postClaimEraPaymentRequest: any = createAsyncThunk(
  'claim-era-auto-post/post-payment/id',
  async (paymentData: PostClaimEraPaymentInterface, thunkAPI: any) => {
    try {
      const response: any = await postClaimEraPaymentAPIRequest(paymentData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const postClaimEraPaymentSlice = createSlice({
  name: 'postClaimEraPayment',
  initialState,
  reducers: {
    clearPostClaimEraPaymentResponse: (state) => {
      state.postClaimEraPaymentLoading = false;
      state.postClaimEraPaymentStatus = 'IDLE';
      state.postClaimEraPaymentData = {};
      state.postClaimEraPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postClaimEraPaymentRequest.pending, (state, action) => {
        state.postClaimEraPaymentLoading = true;
        state.postClaimEraPaymentStatus = 'PENDING';
      })
      .addCase(postClaimEraPaymentRequest.fulfilled, (state, action) => {
        state.postClaimEraPaymentData = action.payload;
        state.postClaimEraPaymentStatus = 'SUCCESS';
        state.postClaimEraPaymentLoading = false;
      })
      .addCase(postClaimEraPaymentRequest.rejected, (state, action) => {
        state.postClaimEraPaymentLoading = false;
        state.postClaimEraPaymentError = action.payload;
        state.postClaimEraPaymentStatus = 'FAILED';
      });
  },
});

export const { clearPostClaimEraPaymentResponse } = postClaimEraPaymentSlice.actions;

export const postClaimEraPaymentSelector = (state: RootState) => state.PostClaimEraPayment;

export default postClaimEraPaymentSlice.reducer;
