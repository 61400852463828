import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import AuthReducer from './slices/auth/authSlice';
import GetUsersReducer from './slices/user/getUserSlice';
import GetUserByIdReducer from './slices/user/getUserByIdSlice';
import AddUserReducer from './slices/user/addUserSlice';
import UpdateUserReducer from './slices/user/updateUserSlice';
import addPatientReducer from './slices/patient/addPatientSlice';
import getPatientReducer from './slices/patient/getPatientSlice';
import addFacilityReducer from './slices/facility/addFacilitySlice';
import editAddressReducer from './slices/user/editAddressSlice';
import getAddressReducer from './slices/user/getAddressSlice';
import getPatientByIdReducer from './slices/patient/getPatientByIdSlice';
import getSystemInsuranceReducer from './slices/system/getSystemInsuranceSlice';
import addInsuranceReducer from './slices/insurance/addInsuranceSlice';
import addPatientAddressReducer from './slices/patient/addPatientAddressSlice';
import addPatientInsuranceReducer from './slices/patient/addPatientInsuranceSlice';
import getProvidersReducer from './slices/provider/getProviderSlice';
import addProviderReducer from './slices/provider/addProviderSlice';
import editPatientAddressReducer from './slices/patient/editPatientAddressSlice';
import deletePatientAddressByIdReducer from './slices/patient/deletePatientAddressSlice';
import fetchPatientAddressByIdReducer from './slices/patient/getPatientAddressByIdSlice';
import getSystemProvidersReducer from './slices/system/getSystemProvidersSlice';
import deletePatientInsuranceReducer from './slices/patient/deletePatientInsuranceSlice';
import fetchPatientInsuranceByIdReducer from './slices/patient/getPatientInsuranceByIdSlice';
import editPatientInsuranceReducer from './slices/patient/editPatientInsuranceSlice';
import fetchProviderByIdReducer from './slices/provider/getProviderByIdSlice';
import editPatientDemographicReducer from './slices/patient/editPatientDemographicSlice';
import editProviderProfileReducer from './slices/provider/editProviderProfileSlice';
import getAddressByIdReducer from './slices/user/getAddressByIdSlice';
import addPatientVisitReducer from './slices/patient/addPatientVisitSlice';
import fetchPatientVisitByIdReducer from './slices/patient/getPatientVisitByIdSlice';
import deletePatientVisitReducer from './slices/patient/deletePatientVisitSlice';
import editPatientVisitReducer from './slices/patient/editPatientVisitSlice';
import getPatientCaseOptionReducer from './slices/patient/getPatientCaseOptionSlice';
import addTransactionPaymentReducer from './slices/patient/addTransactionPaymentSlice';
import getPaymentListReducer from './slices/patient/getPaymentListSlice';
import deletePaymentReducer from './slices/patient/deletePaymentSlice';
import editPaymentReducer from './slices/patient/editPaymentSlice';
import getPaymentByIdReducer from './slices/patient/getPaymentByIdSlice';
import getTransactionsSummaryReducer from './slices/patient/getTransactionsSummarySlice';
import editTransactionResponsibilityReducer from './slices/patient/editTransactionResponsibilitySlice';
import getDataForPatientPaymentAddReducer from './slices/patient/getDataForPatientPaymentAddSlice';
import getFacilitiesReducer from './slices/facility/getFacilitiesSlice';
import getSystemFacilitiesReducer from './slices/system/getSystemFacilitiesSlice';
import getInsuranceReducer from './slices/insurance/getInsuranceSlice';
import fetchPatientCaseByIdReducer from './slices/patient/getPatientCaseByIdSlice';
import addPatientCaseReducer from './slices/patient/addPatientCaseSlice';
import editPatientCaseReducer from './slices/patient/editPatientCaseSlice';
import updatePatientVisitEIByIdReducer from './slices/patient/updateEligibilityInquirySlice';
import fetchPatientByIdDemoReducer from './slices/patient/getPatientByIdDemoSlice';
import printPatientClaimReducer from './slices/patient/printPatientClaimSlice';
import getVisitsReducer from './slices/billing/getVisitsSlice';
import getVisitsStatsReducer from './slices/billing/getVisitsStatsSlice';
import getCPTCodeListReducer from './slices/patient/getCPTCodeListSlice';
import getICDCodeListReducer from './slices/patient/getICDCodeListSlice';
import getVisitNoteHistoryReducer from './slices/patient/getVisitNoteHistorySlice';
import getVisitStatusHistoryReducer from './slices/patient/getVisitStatusHistorySlice';
import deleteVisitCPTCodeReducer from './slices/patient/deleteVisitCPTCodeSlice';
import deleteVisitICDCodeReducer from './slices/patient/deleteVisitICDCodeSlice';
import addVisitCPTCodeReducer from './slices/patient/addVisitCPTCodeSlice';
import addVisitICDCodeReducer from './slices/patient/addVisitICDCodeSlice';
import addVisitNoteReducer from './slices/patient/addVisitNoteSlice';
import updateVisitCPTCodeReducer from './slices/patient/updateVisitCPTCodeSlice';
import updateVisitStatusReducer from './slices/patient/updateVisitStatusSlice';
import addVisitApplyNoteReducer from './slices/patient/addVisitApplyNoteSlice';
import getVisitApplyNoteHistoryReducer from './slices/patient/getVisitApplyNoteHistorySlice';
import addClaimApplyNoteReducer from './slices/patient/addClaimApplyNoteSlice';
import getClaimApplyNoteHistoryReducer from './slices/patient/getClaimApplyNoteHistorySlice';

import addProviderAddressReducer from './slices/provider/addProviderAddressSlice';
import editProviderAddressReducer from './slices/provider/editProviderAddressSlice';
import deleteProviderAddressByIdReducer from './slices/provider/deleteProviderAddressSlice';
import fetchProviderAddressByIdReducer from './slices/provider/getProviderAddressByIdSlice';
import editProviderAddressPrimaryAddressReducer from './slices/provider/editProviderAddressPrimaryAddressSlice';

import addProviderLicenseReducer from './slices/provider/addProviderLicenseSlice';
import editProviderLicenseReducer from './slices/provider/editProviderLicenseSlice';
import deleteProviderLicenseByIdReducer from './slices/provider/deleteProviderLicenseSlice';
import fetchProviderLicenseByIdReducer from './slices/provider/getProviderLicenseByIdSlice';
import editProviderLicensePrimaryLicenseReducer from './slices/provider/editProviderLicensePrimaryLicenseSlice';
import editProviderProfessionalReducer from './slices/provider/editProviderProfessionSlice';
import getICDCodesReducer from './slices/system/getICDCodesSlice';
import getCPTCodesReducer from './slices/system/getCPTCodesSlice';
import getModifiersReducer from './slices/system/getModifiersSlice';
import fetchPatientInsuranceByTypeReducer from './slices/patient/getPatientInsuranceByTypeSlice';

import addProviderCarrierReducer from './slices/provider/addProviderCarrierSlice';
import editProviderCarriersReducer from './slices/provider/editProviderCarrierSlice';
import deleteProviderCarriersByIdReducer from './slices/provider/deleteCarrierSlice';
import fetchProviderCarriersByIdReducer from './slices/provider/getProviderCarrierByIdSlice';
import editProviderCarrierApplicationStatusReducer from './slices/provider/editProviderCarrierApplicationStatusSlice';
import editProviderCarrierPendingInfoReducer from './slices/provider/editProviderCarrierPendingInfoSlice';
import addProviderCarrierFollowUpReducer from './slices/provider/addProviderCarrierFollowUpSlice';

import fetchInsuranceByIdReducer from './slices/insurance/getInsurancebyIdSlice';
import editInsuranceReducer from './slices/insurance/editInsuranceSlice';
import deleteInsuranceReducer from './slices/insurance/deleteInsuranceSlice';

import fetchPatientElByVisitIdReducer from './slices/patient/getPatientEligibilityDataByVisitIdSlice';

import addProviderFacilityReducer from './slices/provider/assignProviderFacilitySlice';
import editProviderFacilityReducer from './slices/provider/editProviderFacilitySlice';
import deleteProviderFacilityReducer from './slices/provider/deleteProviderFacilitySlice';
import getProviderFacilityByIdReducer from './slices/provider/getProviderFacilityByIdSlice';
import getSearchProviderFacilityByIdReducer from './slices/provider/getSearchFacilitiesSlice';

import copyVisitReducer from './slices/patient/CopyVisitSlice';
import fetchViewEIByIdReducer from './slices/patient/getPatientViewEIByIdSlice';
import getClaimsReducer from './slices/billing/getClaimsSlice';
import sendClaimReducer from './slices/billing/sendClaimSlice';
import sendBulkClaimsReducer from './slices/billing/sendBulkClaimsSlice';
import createClaimsReducer from './slices/patient/createClaimsSlice';
import createClaimReducer from './slices/billing/createClaimSlice';

import fetchPatientInsuranceSubscriberReducer from './slices/patient/getInsuranceSubscriberSlice';
import addInsuranceSubscriberReducer from './slices/patient/addInsuranceSubscriberSlice';

import addCPTCodeReducer from './slices/codes/addCPTCodeSlice';

import getClaimByIdReducer from './slices/billing/getClaimByIdSlice';
import editClaimReducer from './slices/billing/editClaimSlice';
import getVisitCPTByIdReducer from './slices/billing/getVisitCPTByIdSlice';
import getSystemVisitStatusReducer from './slices/system/getSystemVisitStatusSlice';
import getIPDataReducer from './slices/system/getIPDataSlice';

import getBatchPaymentReducer from './slices/payments/batchPayments/getBatchPaymentSlice';
import addBatchPaymentReducer from './slices/payments/batchPayments/addBatchPaymentSlice';
import editBatchPaymentReducer from './slices/payments/batchPayments/editBatchPaymentSlice';
import getBatchPaymentDetailsByIdReducer from './slices/payments/batchPayments/getBatchPaymentDetailsByIdSlice';
import getBulkPaymentsListReducer from './slices/payments/batchPayments/getBulkPaymentsListSlice';
import getBatchPaymentByIdReducer from './slices/payments/batchPayments/getBatchPaymentByIdSlice';
import closeBatchPaymentReducer from './slices/payments/batchPayments/closeBatchPaymentSlice';
import getBatchPaymentByIdForApplyReducer from './slices/payments/batchPayments/getBatchPaymentByIdForApplySlice';
import getSystemBillingCodeListReducer from './slices/system/getSystemBillingCodeListSlice';
import addBulkLinePaymentsReducer from './slices/payments/batchPayments/addBulkLinePaymentsSlice';

import getClaimEraAutoPostReducer from './slices/payments/claimEraAutoPost/getClaimEraAutoPostSlice';
import postClaimEraPaymentReducer from './slices/payments/claimEraAutoPost/postClaimEraPaymentSlice';
import downloadClaimEraReducer from './slices/payments/claimEraAutoPost/downloadClaimEraSlice';
import getDenialReportsReducer from './slices/payments/denialReports/getDenialReportsSlice';
import addModifierCodeReducer from './slices/codes/addModifierCodeSlice';
import editModifierCodeReducer from './slices/codes/editModifierCodeSlice';
import getModifierCodeByIdReducer from './slices/codes/getModifierCodeByIdSlice';
import deleteModifierCodeByIdReducer from './slices/codes/deleteModifierCodeByIdSlice';
import addICDCodeReducer from './slices/codes/addICDCodeSlice';
import getModifierListReducer from './slices/codes/modifierListSlices';
import getCPTCodeByIdReducer from './slices/codes/viewCPTCodeSlice';
import addFeeAmountReducer from './slices/codes/addFeeAmountSlice';
import getFeeAmountReducer from './slices/codes/getFeeAmountSlice';
import editCPTCodeReducer from './slices/codes/editCPTCodeSlice';
import getReasonListReducer from './slices/system/getReasonListSlice';
import getClaimStatusListReducer from './slices/system/getClaimStatusListSlice';
import getCategoryListReducer from './slices/system/getCategoryListSlice';
import getARCategoryListReducer from './slices/system/getARCategoryListSlice';
import getClaimHistoryReducer from './slices/patient/getClaimHistorySlice';
import getCarrierListReducer from './slices/system/getCarrierListSlice';
import getCarrierTypeListReducer from './slices/system/getCarrierTypeListSlice';

import getVisitBilledCountReducer from './slices/management/visitBilledCount/getVisitBilledCountSlice';
import getProcedureProductivityReportReducer from './slices/management/procedureProductivityReport/getProcedureProductivityReportSlice';
import getChargeDetailVisitReportReducer from './slices/management/chargeDetailVisitReport/getChargeDetailVisitReportSlice';
import getClientsListReducer from './slices/system/getClientsListSlice';
import getChargeDetailEncounterReportReducer from './slices/management/chargeDetailEncounterReport/getChargeDetailEncounterReportSlice';

import getVisitReasonListReducer from './slices/system/getVisitReasonListSlice';
import getDenialCodeListReducer from './slices/system/getDenialCodeListSlice';
import getRemarkCodeListReducer from './slices/system/getRemarkCodeListSlice';
import getClaimEraFilesReducer from './slices/payments/claimEraFiles/getClaimEraFilesSlice';
import getClaimEraFilesByReceivedDateReducer from './slices/payments/claimEraFiles/getClaimEraFilesByReceivedDateSlice';
import getClaimEraPaymentsReducer from './slices/payments/claimEraFiles/getClaimEraPaymentsSlice';
import verifyClaimEraPaymentReducer from './slices/payments/claimEraFiles/verifyClaimEraPaymentSlice';

import getExpectedVsCollectionsReportReducer from './slices/reports/getExpectedVsCollectionsReportSlice';
import getReimbursementReportReducer from './slices/reports/getReimbursementReportSlice';
import getProviderVisitLagReportReducer from './slices/reports/getProviderVisitLagReportSlice';
import getVisitReportReducer from './slices/reports/getVisitReportSlice';
import getBillingSnapshotReportReducer from './slices/reports/getBillingSnapshotReportSlice';
import getBillingSnapshotSummaryReducer from './slices/reports/getBillingSnapshotSummarySlice';
import getBilledAndUnbilledReportReducer from './slices/reports/getBilledAndUnbilledReportSlice';
import getDoctorFinancialReportReducer from './slices/reports/getDoctorFinancialReportSlice';
import getFinancialDashboardReportReducer from './slices/reports/getFinancialDashboardReportSlice';
import getVisitCountReportReducer from './slices/reports/getVisitCountReportSlice';

import getDashboardCardDataReducer from './slices/dashboard/getDashboardCardDataSlice';
import getDashboardPerformanceTableReducer from './slices/dashboard/getDashboardPerformanceTableSlice';
import getDashboardProviderTableReducer from './slices/dashboard/getDashboardProviderTableSlice';
import getDashboardChartDataReducer from './slices/dashboard/getDashboardPlotDataSlice';

const store = configureStore({
  reducer: {
    Auth: AuthReducer, //convention is to to write the text preceding the word "Reducer"
    GetUser: GetUsersReducer,
    GetUserById: GetUserByIdReducer,
    AddUser: AddUserReducer,
    UpdateUser: UpdateUserReducer,
    AddPatient: addPatientReducer,
    GetPatients: getPatientReducer,
    GetSystemFacilities: getSystemFacilitiesReducer,
    AddFacility: addFacilityReducer,
    EditAddress: editAddressReducer,
    GetAddress: getAddressReducer,
    GetPatientById: getPatientByIdReducer,
    GetSystemInsurance: getSystemInsuranceReducer,
    AddInsurance: addInsuranceReducer,
    AddPatientAddress: addPatientAddressReducer,
    EditPatientAddress: editPatientAddressReducer,
    DeletePatientAddressById: deletePatientAddressByIdReducer,
    FetchPatientAddressById: fetchPatientAddressByIdReducer,
    AddPatientInsurance: addPatientInsuranceReducer,
    AddPatientPayment: addTransactionPaymentReducer,
    GetPatientPaymentList: getPaymentListReducer,
    DeletePatientPayment: deletePaymentReducer,
    EditPatientPayment: editPaymentReducer,
    GetPatientPaymentById: getPaymentByIdReducer,
    GetPatientTransactionsSumamry: getTransactionsSummaryReducer,
    EditTransactionResponsibility: editTransactionResponsibilityReducer,
    GetDataForPatientPaymentAddForm: getDataForPatientPaymentAddReducer,
    GetProviders: getProvidersReducer,
    AddProvider: addProviderReducer,
    AddProviderAddress: addProviderAddressReducer,
    EditProviderAddress: editProviderAddressReducer,
    DeleteProviderAddressById: deleteProviderAddressByIdReducer,
    FetchProviderAddressById: fetchProviderAddressByIdReducer,
    UpdateProviderAddressPrimary: editProviderAddressPrimaryAddressReducer,
    AddProviderCarriers: addProviderCarrierReducer,
    EditProviderCarriers: editProviderCarriersReducer,
    DeleteProviderCarriersById: deleteProviderCarriersByIdReducer,
    FetchProviderCarriersById: fetchProviderCarriersByIdReducer,
    EditProviderCarrierApplicationStatus: editProviderCarrierApplicationStatusReducer,
    EditProviderCarrierPendingInfo: editProviderCarrierPendingInfoReducer,
    AddProviderCarrierFollowUp: addProviderCarrierFollowUpReducer,
    AddProviderLicense: addProviderLicenseReducer,
    EditProviderLicense: editProviderLicenseReducer,
    UpdateProviderLicensePrimary: editProviderLicensePrimaryLicenseReducer,
    DeleteProviderLicenseById: deleteProviderLicenseByIdReducer,
    FetchProviderLicenseById: fetchProviderLicenseByIdReducer,
    EditProviderProfessional: editProviderProfessionalReducer,
    FetchSystemProviders: getSystemProvidersReducer,
    DeletePatientInsurance: deletePatientInsuranceReducer,
    FetchPatientInsuranceById: fetchPatientInsuranceByIdReducer,
    EditPatientInsurance: editPatientInsuranceReducer,
    FetchProviderById: fetchProviderByIdReducer,
    EditPatientDemographic: editPatientDemographicReducer,
    EditProviderProfile: editProviderProfileReducer,
    GetAddressById: getAddressByIdReducer,
    AddPatientVisit: addPatientVisitReducer,
    FetchPatientVisitById: fetchPatientVisitByIdReducer,
    DeletePatientVisit: deletePatientVisitReducer,
    EditPatientVisit: editPatientVisitReducer,
    GetPatientCaseOptions: getPatientCaseOptionReducer,
    GetFacilites: getFacilitiesReducer,
    GetInsurance: getInsuranceReducer,
    FetchInsuranceById: fetchInsuranceByIdReducer,
    EditInsurance: editInsuranceReducer,
    DeleteInsurance: deleteInsuranceReducer,
    FetchPatientCaseById: fetchPatientCaseByIdReducer,
    AddPatientCase: addPatientCaseReducer,
    EditPatientCase: editPatientCaseReducer,
    UpdatePatientVisitEIById: updatePatientVisitEIByIdReducer,
    FetchPatientByIdDemo: fetchPatientByIdDemoReducer,
    PrintPatientClaim: printPatientClaimReducer,
    GetVisits: getVisitsReducer,
    GetVisitsStats: getVisitsStatsReducer,
    CPTList: getCPTCodeListReducer,
    ICDList: getICDCodeListReducer,
    FetchVisitNoteHistory: getVisitNoteHistoryReducer,
    FetchVisitStatusHistory: getVisitStatusHistoryReducer,
    DeleteVisitCPTCode: deleteVisitCPTCodeReducer,
    DeleteVisitICDCode: deleteVisitICDCodeReducer,
    AddVisitCPTCode: addVisitCPTCodeReducer,
    AddVisitICDCode: addVisitICDCodeReducer,
    AddVisitNote: addVisitNoteReducer,
    UpdateVisitCPTCode: updateVisitCPTCodeReducer,
    UpdateVisitStatus: updateVisitStatusReducer,
    AddVisitApplyNote: addVisitApplyNoteReducer,
    FetchVisitApplyNoteHistory: getVisitApplyNoteHistoryReducer,
    AddClaimApplyNote: addClaimApplyNoteReducer,
    FetchClaimApplyNoteHistory: getClaimApplyNoteHistoryReducer,
    GetICDCodes: getICDCodesReducer,
    GetCPTCodes: getCPTCodesReducer,
    GetModifiers: getModifiersReducer,
    FetchPatientInsuranceByType: fetchPatientInsuranceByTypeReducer,
    FetchPatientElByVisitId: fetchPatientElByVisitIdReducer,

    AddProviderFacilities: addProviderFacilityReducer,
    EditProviderFacilities: editProviderFacilityReducer,
    DeleteProviderFacilitiesById: deleteProviderFacilityReducer,
    FetchProviderFacilitiesById: getProviderFacilityByIdReducer,
    GetSearchFacilites: getSearchProviderFacilityByIdReducer,
    CopyVisit: copyVisitReducer,
    FetchViewEIById: fetchViewEIByIdReducer,
    GetClaims: getClaimsReducer,
    SendClaim: sendClaimReducer,
    SendBulkClaims: sendBulkClaimsReducer,
    CreateClaims: createClaimsReducer,
    CreateClaim: createClaimReducer,
    FetchPatientInsuranceSubscriberReducer: fetchPatientInsuranceSubscriberReducer,
    AddPatientInsuranceSubscriber: addInsuranceSubscriberReducer,
    AddCPTCode: addCPTCodeReducer,
    GetClaimById: getClaimByIdReducer,
    EditClaim: editClaimReducer,
    GetVisitCPTById: getVisitCPTByIdReducer,
    GetSystemVisitStatus: getSystemVisitStatusReducer,
    GetIPData: getIPDataReducer,
    GetBatchPayments: getBatchPaymentReducer,
    AddBatchPayment: addBatchPaymentReducer,
    UpdateBatchPayment: editBatchPaymentReducer,
    GetBatchPaymentsTransactions: getBatchPaymentDetailsByIdReducer,
    GetBatchPaymentById: getBatchPaymentByIdReducer,
    CloseBatchPayment: closeBatchPaymentReducer,
    GetBatchPaymentsDataForApply: getBatchPaymentByIdForApplyReducer,
    GetBulkPaymentsList: getBulkPaymentsListReducer,
    GetSystemBillingCodes: getSystemBillingCodeListReducer,
    AddBulkLinePayments: addBulkLinePaymentsReducer,
    GetClaimEraAutoPost: getClaimEraAutoPostReducer,
    PostClaimEraPayment: postClaimEraPaymentReducer,
    DownloadClaimEra: downloadClaimEraReducer,
    GetDenialReports: getDenialReportsReducer,
    GetDenialCodeList: getDenialCodeListReducer,
    GetRemarkCodeList: getRemarkCodeListReducer,
    GetVisitBilledCounts: getVisitBilledCountReducer,
    GetProcedureProductivityReport: getProcedureProductivityReportReducer,
    GetChargeDetailVisitReport: getChargeDetailVisitReportReducer,
    GetClientsList: getClientsListReducer,
    GetChargeDetailEncounterReport: getChargeDetailEncounterReportReducer,
    GetClaimEraFiles: getClaimEraFilesReducer,
    GetClaimEraFilesByReceivedDate: getClaimEraFilesByReceivedDateReducer,
    GetClaimEraPayments: getClaimEraPaymentsReducer,
    VerifyClaimEraPayment: verifyClaimEraPaymentReducer,

    AddModifierCode: addModifierCodeReducer,
    EditModifierCode: editModifierCodeReducer,
    GetModifierCodeById: getModifierCodeByIdReducer,
    DeleteModifierCodeById: deleteModifierCodeByIdReducer,
    AddICDCode: addICDCodeReducer,
    GetModifierList: getModifierListReducer,
    GetCPTCodeById: getCPTCodeByIdReducer,
    AddFeeAmountReducer: addFeeAmountReducer,
    GetFeeAmount: getFeeAmountReducer,
    EditCPTCode: editCPTCodeReducer,
    GetReasonList: getReasonListReducer,
    GetClaimStatus: getClaimStatusListReducer,
    GetCategoryList: getCategoryListReducer,
    GetARCategoryList: getARCategoryListReducer,
    GetClaimHistory: getClaimHistoryReducer,
    GetCarrierList: getCarrierListReducer,
    GetCarrierTypeList: getCarrierTypeListReducer,

    GetExpectedVsCollectionsReport: getExpectedVsCollectionsReportReducer,
    GetReimbursementReport: getReimbursementReportReducer,
    GetProviderVisitLagReport: getProviderVisitLagReportReducer,
    GetVisitReport: getVisitReportReducer,
    GetBillingSnapshotReport: getBillingSnapshotReportReducer,
    GetBillingSnapshotSumamry: getBillingSnapshotSummaryReducer,
    GetBilledAndUnbilledReport: getBilledAndUnbilledReportReducer,
    GetDoctorFinancialReport: getDoctorFinancialReportReducer,
    GetFinancialDashboardReport: getFinancialDashboardReportReducer,
    GetVisitCountReport: getVisitCountReportReducer,

    GetDashboardCardData: getDashboardCardDataReducer,
    GetDashboardPerformanceTable: getDashboardPerformanceTableReducer,
    GetDashboardProviderTable: getDashboardProviderTableReducer,
    GetDashboardPlotData: getDashboardChartDataReducer,

    GetVisitReasonList: getVisitReasonListReducer,
    //enter all your reducers here
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

//Writing these here to prevent defining the types in every file
export const useAppDispatch = () => useDispatch<AppDispatch>(); //This is used to perform action
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
// Used to get the data from the store in the component

export default store;
