import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchSystemBillingCodeListInitialInterface } from '../../../interfaces/systemInterfaces';
import { getSystemBillingCodeListAPIRequest } from '../../../api/system/payment';

const initialState: FetchSystemBillingCodeListInitialInterface = {
  fetchSystemBillingCodeListLoading: false,
  fetchSystemBillingCodeListData: [],
  fetchSystemBillingCodeListError: null,
  fetchSystemBillingCodeListStatus: 'IDLE',
};

export const getSystemBillingCodeListRequest: any = createAsyncThunk(
  'system/billing-codes/get',
  async (thunkAPI: any) => {
    try {
      const response: any = await getSystemBillingCodeListAPIRequest();
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getSystemBillingCodeListSlice = createSlice({
  name: 'fetchSystemBillingCodes',
  initialState,
  reducers: {
    clearFetchSystemBillingCodesResponse: (state) => {
      state.fetchSystemBillingCodeListLoading = false;
      state.fetchSystemBillingCodeListStatus = 'IDLE';
      state.fetchSystemBillingCodeListData = [];
      state.fetchSystemBillingCodeListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemBillingCodeListRequest.pending, (state, action) => {
        state.fetchSystemBillingCodeListLoading = true;
        state.fetchSystemBillingCodeListStatus = 'PENDING';
      })
      .addCase(getSystemBillingCodeListRequest.fulfilled, (state, action) => {
        state.fetchSystemBillingCodeListLoading = false;
        state.fetchSystemBillingCodeListData = action.payload;
        state.fetchSystemBillingCodeListStatus = 'SUCCESS';
      })
      .addCase(getSystemBillingCodeListRequest.rejected, (state, action) => {
        state.fetchSystemBillingCodeListLoading = false;
        state.fetchSystemBillingCodeListError = action.payload;
        state.fetchSystemBillingCodeListStatus = 'FAILED';
      });
  },
});

export const { clearFetchSystemBillingCodesResponse } = getSystemBillingCodeListSlice.actions;

export const getSystemBillingCodeListSelector = (state: RootState) => state.GetSystemBillingCodes;

export default getSystemBillingCodeListSlice.reducer;
