import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientPaymentFetchByIdInterface,
  PatientPaymentDeletePropsInterface,
} from '../../../interfaces/patientInterface';
import { deletePatientPaymentAPIRequest } from '../../../api/patient/patientTransaction';

const initialState: PatientPaymentDeletePropsInterface = {
  deleteTransactionPaymentLoading: false,
  deleteTransactionPaymentData: {},
  deleteTransactionPaymentStatus: 'IDLE',
  deleteTransactionPaymentError: null,
};

export const deletePatientPaymentRequest: any = createAsyncThunk(
  'patient/transaction/paymentDelete',
  async (searchParams: PatientPaymentFetchByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await deletePatientPaymentAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const deletePatientPaymentSlice = createSlice({
  name: 'deletePatientPayment',
  initialState,
  reducers: {
    clearDeletePatientPaymentResponse: (state) => {
      state.deleteTransactionPaymentLoading = false;
      state.deleteTransactionPaymentData = {};
      state.deleteTransactionPaymentStatus = 'IDLE';
      state.deleteTransactionPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deletePatientPaymentRequest.pending, (state, action) => {
        state.deleteTransactionPaymentLoading = true;
        state.deleteTransactionPaymentStatus = 'PENDING';
      })
      .addCase(deletePatientPaymentRequest.fulfilled, (state, action) => {
        state.deleteTransactionPaymentLoading = false;
        state.deleteTransactionPaymentStatus = 'SUCCESS';
        state.deleteTransactionPaymentData = action.payload;
      })
      .addCase(deletePatientPaymentRequest.rejected, (state, action) => {
        state.deleteTransactionPaymentLoading = false;
        state.deleteTransactionPaymentError = action.payload;
        state.deleteTransactionPaymentStatus = 'FAILED';
      });
  },
});

export const { clearDeletePatientPaymentResponse } = deletePatientPaymentSlice.actions;

export const deletePatientPaymentSelector = (state: RootState) => state.DeletePatientPayment;

export default deletePatientPaymentSlice.reducer;
