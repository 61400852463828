import { useState } from 'react';
import { applicationStatus, credentialingStatus, status, usStates } from '../../../../constants/systemConstants';
import { CheckboxPropsInterface } from '../../../../interfaces/atomInterfaces';
import { ProviderCarriersFormPropsInterface } from '../../../../interfaces/providersInterface';
import { Calendar } from '../../../atoms/Calendar';
import { Checkbox } from '../../../atoms/Checkbox';
import { CheckboxGroup } from '../../../atoms/CheckboxGroup';
import { InputField } from '../../../atoms/InputField';
import { SelectInput } from '../../../atoms/SelectInput';
import { TextArea } from '../../../atoms/TextArea';
import { CommonButton } from '../../../atoms/CommonButton';
import { ProviderCarriersFollowUp } from './ProviderCarriersFollowUp';
import { formatDate } from '../../../../utils/commonFunctions';
import { DatePickerField } from '../../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../../utils/commonFunctions';

const pendingInformationCheckboxes = [
  'cds',
  'dea',
  'certificate',
  'hospitalAffiliation',
  'mfa',
  'pendingSignature',
  'pli',
  'token',
];

export const ProviderCarriersForm: React.FC<ProviderCarriersFormPropsInterface> = ({
  onChange,
  onDateChange,
  carriersFormEditData,
  errorObject,
  carriersNames,
  carriersMode,
  onCheckBoxGroupChange,
  selectedCarriersId,
  providerId,
  fetchProviderById,
}) => {
  // const checkboxes: CheckboxPropsInterface[] = [
  //   {
  //     label: 'Checkbox 1',
  //     name: 'checkbox1',
  //     onChange,
  //     defaultChecked: false,
  //   },
  // ];
  const [visibleAddFollowUpModal, setVisibleAddFollowUpModal] = useState(false);

  const checkboxes: CheckboxPropsInterface[] = pendingInformationCheckboxes.map((key) => ({
    label: key,
    name: key,
    onChange,
    defaultChecked: carriersFormEditData?.pendingInformation?.[key] ?? false,
  }));

  //console.log('carriersFormEditData', carriersFormEditData);

  return (
    <>
      {carriersMode === 'EDIT' && (
        <div className="w-36 ml-auto">
          <CommonButton
            label="Add follow up"
            buttonType="primary"
            // icon={
            //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
            //     <path
            //       fill-rule="evenodd"
            //       d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
            //       clip-rule="evenodd"
            //     />
            //   </svg>
            // }
            onClick={() => setVisibleAddFollowUpModal(true)}
          />
        </div>
      )}
      <div className="grid md:grid-cols-1 gap-4 mt-6">
        <SelectInput
          label="Carrier Name"
          options={carriersNames}
          enableDefaultPlaceholder={true}
          name="carrierName"
          onChange={onChange}
          defaultValue={carriersFormEditData?.carrierName ?? undefined}
          // required={true}
        />
        <Checkbox
          label="Credentialled"
          name="credentialled"
          onChange={onChange}
          defaultChecked={carriersFormEditData?.credentialled ?? undefined}
        />
        {carriersMode === 'CREATE' && (
          <>
            <div className="mt-1">Credentialing Not Required</div>
            <div className="mt-2">
              {
                'If credentialing is required for this carrier, uncheck the "Credentailing Not Required" checkbox in the carrier profile under Insurances > Manage Insurances.'
              }
            </div>
          </>
        )}

        <Checkbox
          label="Group Linked"
          name="groupLinked"
          onChange={onChange}
          defaultChecked={carriersFormEditData?.groupLinked ?? undefined}
        />
        <SelectInput
          label="Credentialing Status"
          options={credentialingStatus}
          enableDefaultPlaceholder={true}
          name="credentialingStatus"
          onChange={onChange}
          value={carriersFormEditData?.credentialingStatus ?? undefined}
          // required={true}
        />

        <InputField
          label="PIN:"
          placeholder="PIN"
          name="pin"
          onChange={onChange}
          value={carriersFormEditData?.pin ?? undefined}
          error={errorObject && errorObject?.pin}
          errorMessage={errorObject && errorObject?.pin}
          // required={true}
        />

        <InputField
          label="GRP:"
          placeholder="GRP"
          name="grp"
          onChange={onChange}
          value={carriersFormEditData?.grp ?? undefined}
          error={errorObject && errorObject?.grp}
          errorMessage={errorObject && errorObject?.grp}
          // required={true}
        />

        {/* <Calendar
          label="Initiated Date"
          name="initiatedDate"
          // defaultDate={carriersFormEditData?.initiatedDate ? new Date(carriersFormEditData.initiatedDate) : undefined}
          defaultDate={formatDate(carriersFormEditData?.initiatedDate)}
          onSelectedDateChanged={(date) => {
            onDateChange('initiatedDate', date);
          }}
          // required={true}
        /> */}
        <DatePickerField
          label="Initiated Date"
          name="initiatedDate"
          selectedDate={formatDatePickerDate(carriersFormEditData.initiatedDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('initiatedDate', date);
            }
          }}
          // required={true}
        />

        {/* <Calendar
          label="Effective Date"
          name="effectiveDate"
          // defaultDate={carriersFormEditData?.effectiveDate ? new Date(carriersFormEditData?.effectiveDate) : undefined}
          defaultDate={formatDate(carriersFormEditData?.effectiveDate)}
          onSelectedDateChanged={(date) => {
            onDateChange('effectiveDate', date);
          }}
          // required={true}
        /> */}
        <DatePickerField
          label="Effective Date"
          name="effectiveDate"
          selectedDate={formatDatePickerDate(carriersFormEditData.effectiveDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('effectiveDate', date);
            }
          }}
          // required={true}
        />

        {/* <Calendar
          label="Expiration Date"
          name="expirationDate"
          // defaultDate={
          //   carriersFormEditData?.expirationDate ? new Date(carriersFormEditData?.expirationDate) : undefined
          // }
          defaultDate={formatDate(carriersFormEditData?.expirationDate)}
          onSelectedDateChanged={(date) => {
            onDateChange('expirationDate', date);
          }}
          // required={true}
        /> */}
        <DatePickerField
          label="Expiration Date"
          name="expirationDate"
          selectedDate={formatDatePickerDate(carriersFormEditData.expirationDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('expirationDate', date);
            }
          }}
          // required={true}
        />

        <DatePickerField
          label="Last Revalidation Date"
          name="lastRevalidationDate"
          selectedDate={formatDatePickerDate(carriersFormEditData.lastRevalidationDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('lastRevalidationDate', date);
            }
          }}
        />

        <DatePickerField
          label="Next Revalidation Date"
          name="nextRevalidationDate"
          selectedDate={formatDatePickerDate(carriersFormEditData.nextRevalidationDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('nextRevalidationDate', date);
            }
          }}
        />

        <SelectInput
          label="Application Status"
          options={applicationStatus}
          enableDefaultPlaceholder={true}
          name="applicationStatus"
          onChange={onChange}
          value={carriersFormEditData?.applicationStatus ?? undefined}
          error={errorObject && errorObject?.applicationStatus}
          errorMessage={errorObject && errorObject?.applicationStatus}
          // required={true}
        />

        <CheckboxGroup groupLabel="Pending Information:" checkboxes={checkboxes} onChange={onCheckBoxGroupChange} />

        <TextArea
          onChange={onChange}
          name="note"
          label="Notes"
          defaultValue={carriersFormEditData?.note ?? undefined}
        />

        <SelectInput
          label="Status"
          options={status}
          enableDefaultPlaceholder={true}
          name="status"
          onChange={onChange}
          value={carriersFormEditData?.status ?? undefined}
          error={errorObject && errorObject?.status}
          errorMessage={errorObject && errorObject?.status}
          // required={true}
        />
      </div>
      {visibleAddFollowUpModal && (
        <ProviderCarriersFollowUp
          addFollowUpModalVisible={visibleAddFollowUpModal}
          setAddFollowUpVisible={setVisibleAddFollowUpModal}
          selectedCarriersId={selectedCarriersId}
          providerId={providerId}
          fetchProviderById={fetchProviderById}
        />
      )}
    </>
  );
};
