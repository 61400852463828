import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchDashboardPlotsInitialInterface } from '../../../interfaces/dashboardinterfaces';
import { getDashboardPlotDataAPIRequest } from '../../../api/dashboard/dashboard';

const initialState: FetchDashboardPlotsInitialInterface = {
  fetchDashboardPlotsLoading: false,
  fetchDashboardPlotsData: { billedEncounters: {}, collections: {} },
  fetchDashboardPlotsError: null,
  fetchDashboardPlotsStatus: 'IDLE',
};

export const getDashboardPlotDataRequest: any = createAsyncThunk('dashboard/plot-data/get', async (thunkAPI: any) => {
  try {
    const response: any = await getDashboardPlotDataAPIRequest();
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getDashboardPlotDataSlice = createSlice({
  name: 'fetchDashboardPlotData',
  initialState,
  reducers: {
    clearFetchDashboardPlotDataResponse: (state) => {
      state.fetchDashboardPlotsLoading = false;
      state.fetchDashboardPlotsStatus = 'IDLE';
      state.fetchDashboardPlotsData = { billedEncounters: {}, collections: {} };
      state.fetchDashboardPlotsError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardPlotDataRequest.pending, (state, action) => {
        state.fetchDashboardPlotsLoading = true;
        state.fetchDashboardPlotsStatus = 'PENDING';
      })
      .addCase(getDashboardPlotDataRequest.fulfilled, (state, action) => {
        state.fetchDashboardPlotsLoading = false;
        state.fetchDashboardPlotsData = action.payload;
        state.fetchDashboardPlotsStatus = 'SUCCESS';
      })
      .addCase(getDashboardPlotDataRequest.rejected, (state, action) => {
        state.fetchDashboardPlotsLoading = false;
        state.fetchDashboardPlotsError = action.payload;
        state.fetchDashboardPlotsStatus = 'FAILED';
      });
  },
});

export const { clearFetchDashboardPlotDataResponse } = getDashboardPlotDataSlice.actions;

export const getDashboardPlotDataSelector = (state: RootState) => state.GetDashboardPlotData;

export default getDashboardPlotDataSlice.reducer;
