import { useState } from 'react';

import { useAppSelector } from '../../../hooks/storeHooks/hooks';

import { CommonButton } from '../../atoms/CommonButton';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { SelectInput } from '../../atoms/SelectInput';
import { FaFileExcel } from 'react-icons/fa';

import { getSystemInsuranceSelector } from '../../../redux/slices/system/getSystemInsuranceSlice';
import { getSystemProvidersSelector } from '../../../redux/slices/system/getSystemProvidersSlice';
import { getDenialCodeListSelector } from '../../../redux/slices/system/getDenialCodeListSlice';
import { getRemarkCodeListSelector } from '../../../redux/slices/system/getRemarkCodeListSlice';

import { convertingToDropdownArr } from '../../../utils/commonFunctions';

import { DenialReportsInterface } from '../../../interfaces/userInterface';

const dummyDenialCodeList = [
  {
    id: 'CO-212',
    name: 'C0-212',
  },
  {
    id: 'CO-356',
    name: 'C0-356',
  },
  {
    id: '122',
    name: '122',
  },
  {
    id: 'CO-115',
    name: 'CO-115',
  },
  {
    id: 'CO-315',
    name: 'CO-315',
  },
];

const dummyRemarkCodeList = [
  {
    id: 'M32',
    name: 'M32',
  },
  {
    id: 'M56',
    name: 'M56',
  },
  {
    id: 'M12',
    name: 'M12',
  },
  {
    id: 'M45',
    name: 'M45',
  },
  {
    id: 'M78',
    name: 'M78',
  },
];

interface RemarkCode {
  id?: number;
  code?: string;
}

export const DenialReportHeader: React.FC<DenialReportsInterface> = ({ onSubmit }) => {
  const { systemInsuranceInsuranceData } = useAppSelector(getSystemInsuranceSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);
  const { denialCodeListData } = useAppSelector(getDenialCodeListSelector);
  const { remarkCodeListData } = useAppSelector(getRemarkCodeListSelector);

  const [filterObject, setFilterObject] = useState<any>({});

  const onChangeFilter = (e: any) => {
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {
    const selectedRemarkCode = (remarkCodeListData as RemarkCode[])?.find(
      (code) => code.id === Number(filterObject?.remarkCode)
    );

    const updatedFilterObject = {
      ...filterObject,
      remarkCode: selectedRemarkCode?.code || '',
    };

    onSubmit(updatedFilterObject);
  };

  // console.log('Filter', filterObject);
  return (
    <>
      <>
        <div className="grid md:grid-cols-5 gap-4 mt-6 mb-4">
          <SelectInput
            label=""
            options={convertingToDropdownArr(denialCodeListData)}
            defaultPlaceholder="Select Denial Code"
            name="denialCode"
            onChange={onChangeFilter}
            value={filterObject?.denialCode ?? ''}
          />
          <SelectInput
            label=""
            options={convertingToDropdownArr(remarkCodeListData)}
            defaultPlaceholder="Select Remark Code"
            name="remarkCode"
            onChange={onChangeFilter}
            value={filterObject?.remarkCode ?? ''}
          />

          <SelectInput
            label=""
            options={systemProvidersProviderData}
            defaultPlaceholder="Select Provider / PA"
            name="providerId"
            onChange={onChangeFilter}
            value={filterObject?.providerId ?? ''}
          />

          <SelectInput
            label=""
            options={systemInsuranceInsuranceData}
            defaultPlaceholder="Select Insurance"
            name="insuranceId"
            onChange={onChangeFilter}
            value={filterObject?.insuranceId ?? ''}
          />

          <div className="grid md:grid-cols-3 gap-4 items-end">
            <PrimaryButton
              type="button"
              label="Submit"
              style={{ height: '40px', maxWidth: '120px' }}
              onClick={handleSubmit}
            />
            <CommonButton
              label="Reset"
              buttonType="secondary"
              style={{ height: '40px', maxWidth: '100px' }}
              onClick={() => {
                setFilterObject({});
                onSubmit({});
              }}
            />
            <PrimaryButton
              label="Export to Excel"
              icon={<FaFileExcel style={{ fontSize: '18px' }} />}
              style={{ height: '40px', maxWidth: '100px' }}
            />
          </div>
        </div>
      </>
    </>
  );
};
