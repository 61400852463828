import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchVisitCountReportInterface,
  FetchVisitCountReportInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getVisitCountReportAPIRequest } from '../../../api/reports/visitCountReport';

const initialState: FetchVisitCountReportInitialInterface = {
  fetchVisitCountReportLoading: false,
  fetchVisitCountReportData: [],
  fetchVisitCountReportError: null,
  fetchVisitCountReportStatus: 'IDLE',
};

export const getVisitCountReportRequest: any = createAsyncThunk(
  'reports/visit-count/get',
  async (reportData: FetchVisitCountReportInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getVisitCountReportAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getVisitCountReportSlice = createSlice({
  name: 'fetchVisitCountReport',
  initialState,
  reducers: {
    clearVisitCountReportResponse: (state) => {
      state.fetchVisitCountReportLoading = false;
      state.fetchVisitCountReportData = [];
      state.fetchVisitCountReportStatus = 'IDLE';
      state.fetchVisitCountReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVisitCountReportRequest.pending, (state, action) => {
        state.fetchVisitCountReportLoading = true;
        state.fetchVisitCountReportStatus = 'PENDING';
      })
      .addCase(getVisitCountReportRequest.fulfilled, (state, action) => {
        state.fetchVisitCountReportLoading = false;
        state.fetchVisitCountReportStatus = 'SUCCESS';
        state.fetchVisitCountReportData = action.payload;
      })
      .addCase(getVisitCountReportRequest.rejected, (state, action) => {
        state.fetchVisitCountReportLoading = false;
        state.fetchVisitCountReportStatus = 'FAILED';
        state.fetchVisitCountReportError = action.payload;
      });
  },
});

export const { clearVisitCountReportResponse } = getVisitCountReportSlice.actions;

export const getVisitCountReportSelector = (state: RootState) => state.GetVisitCountReport;

export default getVisitCountReportSlice.reducer;
