import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Spinner } from '../../components/atoms/Spinner';
import { FinancialDashboardReportAdvanceSearch } from '../../components/tableSearch/FinancialDashboardReportAdvanceSearch';

import {
  getFinancialDashboardReportRequest,
  getFinancialDashboardReportSelector,
  clearFinancialDashboardReportResponse,
} from '../../redux/slices/reports/getFinancialDashboardReportSlice';
import { getSystemProvidersRequest } from '../../redux/slices/system/getSystemProvidersSlice';
import { getSystemFacilityRequest } from '../../redux/slices/system/getSystemFacilitiesSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const columns = [
  {
    title: 'Month',
    dataIndex: 'month',
    key: 'month',
  },
  {
    title: '# of Visits',
    dataIndex: 'noOfVisits',
    key: 'noOfVisits',
  },
  {
    title: 'Charges',
    dataIndex: 'charges',
    key: 'charges',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Charge Adjust',
    dataIndex: 'chargeAdjust',
    key: 'chargeAdjust',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Insurance Write Off',
    dataIndex: 'insuranceWriteOff',
    key: 'insuranceWriteOff',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Net Charges',
    dataIndex: 'netCharges',
    key: 'netCharges',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Personal Receipts',
    dataIndex: 'personalReceipts',
    key: 'personalReceipts',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Insurance Receipts',
    dataIndex: 'insuranceReceipts',
    key: 'insuranceReceipts',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Receipt Adjust',
    dataIndex: 'receiptAdjust',
    key: 'receiptAdjust',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Net Receipts',
    dataIndex: 'netReceipts',
    key: 'netReceipts',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Charge In A/R',
    dataIndex: 'chargeInAR',
    key: 'chargeInAR',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = {
  items: [
    {
      month: 'January',
      noOfVisits: 0,
      charges: 0.0,
      chargeAdjust: 0.0,
      insuranceWriteOff: 0.0,
      netCharges: 0.0,
      personalReceipts: 0.0,
      insuranceReceipts: 0.0,
      receiptAdjust: 0.0,
      netReceipts: 0.0,
      changeInAR: 0.0,
    },
    {
      month: 'May',
      noOfVisits: 4013,
      charges: 657594.0,
      chargeAdjust: 6688.51,
      insuranceWriteOff: 231211.02,
      netCharges: 419694.47,
      personalReceipts: 0.0,
      insuranceReceipts: 372499.15,
      receiptAdjust: 0.0,
      netReceipts: 372499.15,
      changeInAR: 47195.32,
    },
    {
      month: 'June',
      noOfVisits: 3587,
      charges: 578306.0,
      chargeAdjust: 5849.04,
      insuranceWriteOff: 202535.83,
      netCharges: 369921.13,
      personalReceipts: 0.0,
      insuranceReceipts: 330792.04,
      receiptAdjust: 0.0,
      netReceipts: 330792.04,
      changeInAR: 39129.09,
    },
    {
      month: 'July',
      noOfVisits: 3473,
      charges: 579020.0,
      chargeAdjust: 5200.49,
      insuranceWriteOff: 201800.15,
      netCharges: 372019.36,
      personalReceipts: 0.0,
      insuranceReceipts: 331641.92,
      receiptAdjust: 0.0,
      netReceipts: 331641.92,
      changeInAR: 40377.44,
    },
    {
      month: 'September',
      noOfVisits: 2661,
      charges: 433806.0,
      chargeAdjust: 5148.24,
      insuranceWriteOff: 96754.43,
      netCharges: 331903.33,
      personalReceipts: 0.0,
      insuranceReceipts: 153698.14,
      receiptAdjust: 0.0,
      netReceipts: 153698.14,
      changeInAR: 178205.19,
    },
    {
      month: 'November',
      noOfVisits: 0,
      charges: 0.0,
      chargeAdjust: 0.0,
      insuranceWriteOff: 0.0,
      netCharges: 0.0,
      personalReceipts: 0.0,
      insuranceReceipts: 0.0,
      receiptAdjust: 0.0,
      netReceipts: 0.0,
      changeInAR: 0.0,
    },
    {
      month: 'December',
      noOfVisits: 0,
      charges: 0.0,
      chargeAdjust: 0.0,
      insuranceWriteOff: 0.0,
      netCharges: 0.0,
      personalReceipts: 0.0,
      insuranceReceipts: 0.0,
      receiptAdjust: 0.0,
      netReceipts: 0.0,
      changeInAR: 0.0,
    },
  ],
  totals: {
    noOfVisits: 16944,
    charges: 2764540.0,
    chargeAdjust: 31870.06,
    insuranceWriteOff: 885463.99,
    netCharges: 1847205.95,
    personalReceipts: 0.0,
    insuranceReceipts: 1449922.92,
    receiptAdjust: 0.0,
    netReceipts: 1449922.92,
    changeInAR: 397283.03,
  },
};

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'financial-dashboard', label: 'Financial Dashboard Report', status: 'active', link: 'financial-dashboard' },
];

export const FinancialDashboardPage = () => {
  const dispatch = useAppDispatch();

  const {
    fetchFinancialDashboardReportLoading,
    fetchFinancialDashboardReportStatus,
    fetchFinancialDashboardReportData,
  } = useAppSelector(getFinancialDashboardReportSelector);

  const currentYear = moment().year();

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({ year: currentYear.toString(), filterBy: 'DOS' });

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    const updatedDataSource = [...dummyDataSource?.items, { ...dummyDataSource?.totals, month: 'Total This Year' }];
    setDataSource(updatedDataSource);

    fetchSystemProviders();
    fetchSystemFacility();
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchFinancialDashboardReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchFinancialDashboardReportStatus === 'SUCCESS') {
      dispatch(clearFinancialDashboardReportResponse());
    } else if (fetchFinancialDashboardReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFinancialDashboardReportResponse());
    }
  }, [fetchFinancialDashboardReportStatus]);

  const fetchFinancialDashboardReport = async () => {
    dispatch(getFinancialDashboardReportRequest({ filters: filterObject }));
  };

  const fetchSystemProviders = () => {
    dispatch(getSystemProvidersRequest());
  };

  const fetchSystemFacility = () => {
    dispatch(getSystemFacilityRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.providerType || updatedData?.providerType === '') {
      delete updatedData.providerType;
    } else {
      updatedData.providerType = parseInt(updatedData?.providerType, 10);
    }

    if (!updatedData?.facilityId || updatedData?.facilityId === '') {
      delete updatedData.facilityId;
    } else {
      updatedData.facilityId = parseInt(updatedData?.facilityId, 10);
    }

    if (!updatedData?.state || updatedData?.state === '') {
      delete updatedData.state;
    }

    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Financial Dashboard Report" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <FinancialDashboardReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchFinancialDashboardReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <div className="overflow-x-auto">
              <TableContent
                enableActions={false}
                columns={columns}
                dataSource={dataSource}
                enableLastRowStyles={true}
                lastRowCellStyles="font-bold"
              />
            </div>
          ) : (
            <EmptyContent enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
