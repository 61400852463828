import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchDashboardProvidersTableInitialInterface } from '../../../interfaces/dashboardinterfaces';
import { getDashboardProviderTableDataAPIRequest } from '../../../api/dashboard/dashboard';

const initialState: FetchDashboardProvidersTableInitialInterface = {
  fetchDashboardProvidersTableLoading: false,
  fetchDashboardProvidersTableData: {},
  fetchDashboardProvidersTableError: null,
  fetchDashboardProvidersTableStatus: 'IDLE',
};

export const getDashboardProviderTableDataRequest: any = createAsyncThunk(
  'dashboard/provider-table/get',
  async (thunkAPI: any) => {
    try {
      const response: any = await getDashboardProviderTableDataAPIRequest();
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getDashboardProviderTableDataSlice = createSlice({
  name: 'fetchDashboardProviderTable',
  initialState,
  reducers: {
    clearFetchDashboardProviderTableResponse: (state) => {
      state.fetchDashboardProvidersTableLoading = false;
      state.fetchDashboardProvidersTableStatus = 'IDLE';
      state.fetchDashboardProvidersTableData = {};
      state.fetchDashboardProvidersTableError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardProviderTableDataRequest.pending, (state, action) => {
        state.fetchDashboardProvidersTableLoading = true;
        state.fetchDashboardProvidersTableStatus = 'PENDING';
      })
      .addCase(getDashboardProviderTableDataRequest.fulfilled, (state, action) => {
        state.fetchDashboardProvidersTableLoading = false;
        state.fetchDashboardProvidersTableData = action.payload;
        state.fetchDashboardProvidersTableStatus = 'SUCCESS';
      })
      .addCase(getDashboardProviderTableDataRequest.rejected, (state, action) => {
        state.fetchDashboardProvidersTableLoading = false;
        state.fetchDashboardProvidersTableError = action.payload;
        state.fetchDashboardProvidersTableStatus = 'FAILED';
      });
  },
});

export const { clearFetchDashboardProviderTableResponse } = getDashboardProviderTableDataSlice.actions;

export const getDashboardProviderTableDataSelector = (state: RootState) => state.GetDashboardProviderTable;

export default getDashboardProviderTableDataSlice.reducer;
