import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchDashboardCardDataInitialInterface } from '../../../interfaces/dashboardinterfaces';
import { getDashboardCardDataAPIRequest } from '../../../api/dashboard/dashboard';

const initialState: FetchDashboardCardDataInitialInterface = {
  fetchDashboardCardDataLoading: false,
  fetchDasboardCardData: {},
  fetchDashboardCardDataError: null,
  fetchDashboardCardDataStatus: 'IDLE',
};

export const getDashboardCardDataRequest: any = createAsyncThunk('dashboard/data-card/get', async (thunkAPI: any) => {
  try {
    const response: any = await getDashboardCardDataAPIRequest();
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getDashboardCardDataSlice = createSlice({
  name: 'fetchDashboardCardData',
  initialState,
  reducers: {
    clearFetchDashboardCardDataResponse: (state) => {
      state.fetchDashboardCardDataLoading = false;
      state.fetchDashboardCardDataStatus = 'IDLE';
      state.fetchDasboardCardData = {};
      state.fetchDashboardCardDataError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardCardDataRequest.pending, (state, action) => {
        state.fetchDashboardCardDataLoading = true;
        state.fetchDashboardCardDataStatus = 'PENDING';
      })
      .addCase(getDashboardCardDataRequest.fulfilled, (state, action) => {
        state.fetchDashboardCardDataLoading = false;
        state.fetchDasboardCardData = action.payload;
        state.fetchDashboardCardDataStatus = 'SUCCESS';
      })
      .addCase(getDashboardCardDataRequest.rejected, (state, action) => {
        state.fetchDashboardCardDataLoading = false;
        state.fetchDashboardCardDataError = action.payload;
        state.fetchDashboardCardDataStatus = 'FAILED';
      });
  },
});

export const { clearFetchDashboardCardDataResponse } = getDashboardCardDataSlice.actions;

export const getDashboardCardDataSelector = (state: RootState) => state.GetDashboardCardData;

export default getDashboardCardDataSlice.reducer;
