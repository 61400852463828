import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  FetchBatchPaymentDetailsByIdInterface,
  FetchBatchPaymentTransactionsByIdPropsInterface,
} from '../../../../interfaces/paymentInterfaces';
import { getBatchPaymentTransactionsByIdAPIRequest } from '../../../../api/payments/batchPayments';

const initialState: FetchBatchPaymentTransactionsByIdPropsInterface = {
  fetchBatchPaymentTransactionsByIdLoading: false,
  fetchBatchPaymentTransactionsByIdData: { linePayments: [], totalAmount: 0 },
  fetchBatchPaymentTransactionsByIdError: null,
  fetchBatchPaymentTransactionsByIdStatus: 'IDLE',
};

export const getBatchPaymentTransactionsByIdRequest: any = createAsyncThunk(
  'batch-payment/get-transactions/id',
  async (searchParams: FetchBatchPaymentDetailsByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getBatchPaymentTransactionsByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchBatchPaymentTransactionsByIdSlice = createSlice({
  name: 'fetchBatchPaymentTransactionsById',
  initialState,
  reducers: {
    clearFetchBatchPaymentTransactionsByIdResponse: (state) => {
      state.fetchBatchPaymentTransactionsByIdLoading = false;
      state.fetchBatchPaymentTransactionsByIdData = { linePayments: [], totalAmount: 0 };
      state.fetchBatchPaymentTransactionsByIdStatus = 'IDLE';
      state.fetchBatchPaymentTransactionsByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBatchPaymentTransactionsByIdRequest.pending, (state, action) => {
        state.fetchBatchPaymentTransactionsByIdLoading = true;
        state.fetchBatchPaymentTransactionsByIdStatus = 'PENDING';
      })
      .addCase(getBatchPaymentTransactionsByIdRequest.fulfilled, (state, action) => {
        state.fetchBatchPaymentTransactionsByIdLoading = false;
        state.fetchBatchPaymentTransactionsByIdStatus = 'SUCCESS';
        state.fetchBatchPaymentTransactionsByIdData = action.payload;
      })
      .addCase(getBatchPaymentTransactionsByIdRequest.rejected, (state, action) => {
        state.fetchBatchPaymentTransactionsByIdLoading = false;
        state.fetchBatchPaymentTransactionsByIdStatus = 'FAILED';
        state.fetchBatchPaymentTransactionsByIdError = action.payload;
      });
  },
});

export const { clearFetchBatchPaymentTransactionsByIdResponse } = fetchBatchPaymentTransactionsByIdSlice.actions;

export const getBatchPaymentTransactionsByIdSelector = (state: RootState) => state.GetBatchPaymentsTransactions;

export default fetchBatchPaymentTransactionsByIdSlice.reducer;
