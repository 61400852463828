import { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../../components/atoms/Alert';
import { Spinner } from '../../../components/atoms/Spinner';
import { ClaimERAFilesHeader } from '../../../components/billing/claimERAFiles/ClaimERAFilesHeader';

import {
  getClaimEraFilesRequest,
  getClaimEraFilesSelector,
  clearFetchClaimEraFilesResponse,
} from '../../../redux/slices/payments/claimEraFiles/getClaimEraFilesSlice';

import { formatCurrency, validDateFormat } from '../../../utils/commonFunctions';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Paid Date',
    dataIndex: 'paidDate',
    key: 'paidDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'ERA ID',
    dataIndex: 'fileList',
    key: 'eraIds',
    render: (fileList: any[]) => (
      <div>
        {fileList?.map((file, index) => (
          <div
            key={index}
            className={`${file?.verifiedBy ? 'text-customGreen' : 'text-customRed'} cursor-pointer`}
            title={
              file?.verifiedBy
                ? `${file.verifiedBy} - ${moment(file?.verifiedDate).format('MM/DD/YYYY HH:mm')}`
                : 'Verify'
            }
          >
            {file?.eraId}
          </div>
        ))}
      </div>
    ),
  },
  {
    title: 'Files',
    dataIndex: 'fileList',
    key: 'files',
    render: (fileList: any[]) => (
      <div>
        {fileList?.map((file, index) => (
          <div key={index}>
            {file?.fileUrl ? (
              <a href={file.fileUrl} target="_blank" className="text-linkBlue">
                {file.fileUrl.split('/').pop()}
              </a>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    ),
  },
  {
    title: 'Check Number',
    dataIndex: 'fileList',
    key: 'checkNumbers',
    render: (fileList: any[]) => (
      <div>
        {fileList?.map((file, index) => (
          <div key={index}>{file?.checkNumber}</div>
        ))}
      </div>
    ),
  },
  {
    title: 'Paid Amount',
    dataIndex: 'fileList',
    key: 'paidAmounts',
    render: (fileList: any[]) => (
      <div>
        {fileList?.map((file, index) => (
          <div key={index}>{formatCurrency(file?.paidAmount ?? 0)}</div>
        ))}
      </div>
    ),
  },
  {
    title: 'Total Amount',
    dataIndex: 'totalPaidAmount',
    key: 'totalPaidAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = {
  items: [
    {
      date: '2024-11-16T07:36:57.929+00:00',
      fileList: [
        {
          eraId: 33162714,
          fileUrl: 'https://amc.simplibill.io/claimeraresponce/download/adSJ2ckKKpYNXmRU5ZBDvoVI6_33162714.pdf',
          checkNumber: '12445675',
          paidAmount: 478.3,
        },
        {
          eraId: 33162714,
          fileUrl: 'https://amc.simplibill.io/claimeraresponce/download/adSJ2ckKKpYNXmRU5ZBDvoVI6_33156301.pdf',
          checkNumber: '52445675',
          paidAmount: 182.29,
        },
      ],
      totalAmount: 12703.34,
    },
    {
      date: '2024-11-16T07:36:57.929+00:00',
      fileList: [
        {
          eraId: 33162714,
          fileUrl: 'https://amc.simplibill.io/claimeraresponce/download/adSJ2ckKKpYNXmRU5ZBDvoVI6_33162714.pdf',
          checkNumber: '12445675',
          paidAmount: 478.3,
        },
      ],
      totalAmount: 12703.34,
    },
    {
      date: '2024-11-14T10:45:30.000+00:00',
      fileList: [
        {
          eraId: null,
          fileUrl: null,
          checkNumber: null,
          paidAmount: null,
        },
      ],
      totalAmount: 3429.45,
    },
  ],
};

export const months = [
  { id: '05-2023', name: 'May - 2023' },
  { id: '06-2023', name: 'June - 2023' },
  { id: '07-2023', name: 'July - 2023' },
  { id: '08-2023', name: 'August - 2023' },
  { id: '09-2023', name: 'September - 2023' },
  { id: '10-2023', name: 'October - 2023' },
  { id: '11-2023', name: 'November - 2023' },
  { id: '12-2023', name: 'December - 2023' },
  { id: '01-2024', name: 'January - 2024' },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'claimEraFiles', label: 'Claim ERA Files', status: 'active', link: 'claim-era-files' },
];

export const ClaimERAFilesPage = () => {
  const dispatch = useAppDispatch();

  const { fetchClaimEraFilesStatus, fetchClaimEraFilesData, fetchClaimEraFilesLoading } =
    useAppSelector(getClaimEraFilesSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({});

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalEra, setTotalEra] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchClaimEraFiles();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchClaimEraFilesStatus === 'SUCCESS') {
      setDataSource(fetchClaimEraFilesData?.items);
      setCurrentPage(fetchClaimEraFilesData?.currentPage);
      setTotalPages(fetchClaimEraFilesData?.totalPages);
      setTotalEra(fetchClaimEraFilesData?.totalEra);
      dispatch(clearFetchClaimEraFilesResponse());
    } else if (fetchClaimEraFilesStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchClaimEraFilesResponse());
    }
  }, [fetchClaimEraFilesStatus]);

  const generateMonthsArray = () => {
    const currentMonth = moment();
    const startMonth = moment('01-2023', 'MM-YYYY');
    const monthsCount = currentMonth.diff(startMonth, 'months', true) + 1;

    return Array.from({ length: monthsCount }, (_, i) => {
      const month = moment(startMonth).add(i, 'months');
      return { id: month.format('MMM-YYYY'), name: month.format('MMMM - YYYY') };
    });
  };

  const formattedMonths = generateMonthsArray();

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchClaimEraFiles(page - 1);
  };

  const fetchClaimEraFiles = async (pageNumber = currentPage) => {
    dispatch(getClaimEraFilesRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.month || updatedData?.month === '') {
      delete updatedData.month;
    }

    if (!updatedData?.checkNumber || updatedData?.checkNumber === '') {
      delete updatedData.checkNumber;
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Claim ERA Files" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <ClaimERAFilesHeader totalERA={totalEra ?? 0} months={formattedMonths} showInputField onSubmit={handleSearch} />
      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <div className="flex justify-end">
        <TableSearch />
      </div>
      {fetchClaimEraFilesLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <>
              <div className={`${totalPages > 1 ? '' : 'pb-7'}`}>
                <TableContent enableActions={false} columns={columns} dataSource={dataSource} />
              </div>
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
