import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { PatientPaymentAddInterface, PatientPaymentAddPropsInterface } from '../../../interfaces/patientInterface';
import { addPatientPaymentAPIRequest } from '../../../api/patient/patientTransaction';

const initialState: PatientPaymentAddPropsInterface = {
  addTransactionPaymentLoading: false,
  addTransactionPaymentData: {},
  addTransactionPaymentError: null,
  addTransactionPaymentStatus: 'IDLE',
};

export const addPatientPaymentRequest: any = createAsyncThunk(
  'patient/transaction/payment-add',
  async (payment: PatientPaymentAddInterface, thunkAPI: any) => {
    try {
      const response: any = await addPatientPaymentAPIRequest(payment);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addPatientPaymentSlice = createSlice({
  name: 'addPatientPayment',
  initialState,
  reducers: {
    clearAddPatientPaymentResponse: (state) => {
      state.addTransactionPaymentLoading = false;
      state.addTransactionPaymentStatus = 'IDLE';
      state.addTransactionPaymentData = {};
      state.addTransactionPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPatientPaymentRequest.pending, (state, action) => {
        state.addTransactionPaymentLoading = true;
        state.addTransactionPaymentStatus = 'PENDING';
      })
      .addCase(addPatientPaymentRequest.fulfilled, (state, action) => {
        state.addTransactionPaymentData = action.payload;
        state.addTransactionPaymentStatus = 'SUCCESS';
        state.addTransactionPaymentLoading = false;
      })
      .addCase(addPatientPaymentRequest.rejected, (state, action) => {
        state.addTransactionPaymentLoading = false;
        state.addTransactionPaymentError = action.payload;
        state.addTransactionPaymentStatus = 'FAILED';
      });
  },
});

export const { clearAddPatientPaymentResponse } = addPatientPaymentSlice.actions;

export const addPatientPaymentSelector = (state: RootState) => state.AddPatientPayment;

export default addPatientPaymentSlice.reducer;
