import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientPaymentFetchByIdInterface,
  FetchPatientPaymentByIdPropsInterface,
} from '../../../interfaces/patientInterface';
import { getPaymentByIdAPIRequest } from '../../../api/patient/patientTransaction';

const initialState: FetchPatientPaymentByIdPropsInterface = {
  fetchTransactionPaymentByIdLoading: false,
  fetchTransactionPaymentByIdData: {},
  fetchTransactionPaymentByIdStatus: 'IDLE',
  fetchTransactionPaymentByIdError: null,
};

export const getPaymentByIdRequest: any = createAsyncThunk(
  'patient/transaction/payment/id',
  async (searchParams: PatientPaymentFetchByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getPaymentByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getPaymentByIdSlice = createSlice({
  name: 'fetchPaymentById',
  initialState,
  reducers: {
    clearFetchPaymentByIdResponse: (state) => {
      state.fetchTransactionPaymentByIdLoading = false;
      state.fetchTransactionPaymentByIdStatus = 'IDLE';
      state.fetchTransactionPaymentByIdData = {};
      state.fetchTransactionPaymentByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentByIdRequest.pending, (state, action) => {
        state.fetchTransactionPaymentByIdLoading = true;
        state.fetchTransactionPaymentByIdStatus = 'PENDING';
      })
      .addCase(getPaymentByIdRequest.fulfilled, (state, action) => {
        state.fetchTransactionPaymentByIdLoading = false;
        state.fetchTransactionPaymentByIdStatus = 'SUCCESS';
        state.fetchTransactionPaymentByIdData = action.payload;
      })
      .addCase(getPaymentByIdRequest.rejected, (state, action) => {
        state.fetchTransactionPaymentByIdLoading = false;
        state.fetchTransactionPaymentByIdError = action.payload;
        state.fetchTransactionPaymentByIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchPaymentByIdResponse } = getPaymentByIdSlice.actions;

export const getPaymentByIdSelector = (state: RootState) => state.GetPatientPaymentById;

export default getPaymentByIdSlice.reducer;
