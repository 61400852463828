import { authenticatedRequest } from '../../utils/commonAxios';
import {
  FetchClaimEraFilesInterface,
  FetchClaimEraPaymentsInterface,
  VerifyClaimEraPaymentInterface,
} from '../../interfaces/paymentInterfaces';

export const getClaimEraFilesAPIRequest = async (searchParams: FetchClaimEraFilesInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/payment/claim-era-file/search?page=${searchParams?.page}&size=${searchParams?.size}`,
    'post',
    {
      data: searchParams?.filters ?? {},
    }
  );
};

export const getClaimEraFilesByReceivedDateAPIRequest = async (searchParams: FetchClaimEraFilesInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/payment/claim-era-file-received/search?page=${searchParams?.page}&size=${searchParams?.size}`,
    'post',
    {
      data: searchParams?.filters ?? {},
    }
  );
};

export const getClaimEraPaymentsAPIRequest = async (searchParams: FetchClaimEraPaymentsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/payment/claimmd-era/search?page=${searchParams?.page}&size=${searchParams?.size}`,
    'post',
    {
      data: searchParams?.filters ?? {},
    }
  );
};

export const verifyClaimEraPaymentAPIRequest = async (paymentData: VerifyClaimEraPaymentInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/payment/claimmd-era/${paymentData?.claimEraId}/verify`,
    'post',
    {}
  );
};
