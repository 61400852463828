import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchDashboardPerformanceTableInitialInterface } from '../../../interfaces/dashboardinterfaces';
import { getDashboardPerformanceTableDataAPIRequest } from '../../../api/dashboard/dashboard';

const initialState: FetchDashboardPerformanceTableInitialInterface = {
  fetchDashboardPerformanceTableLoading: false,
  fetchDashboardPerformanceTableData: {},
  fetchDashboardPerformanceTableError: null,
  fetchDashboardPerformanceTableStatus: 'IDLE',
};

export const getDashboardPerformanceTableDataRequest: any = createAsyncThunk(
  'dashboard/performance-table/get',
  async (thunkAPI: any) => {
    try {
      const response: any = await getDashboardPerformanceTableDataAPIRequest();
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getDashboardPerformanceTableDataSlice = createSlice({
  name: 'fetchDashboardPerformanceTable',
  initialState,
  reducers: {
    clearFetchDashboardPerformanceTableResponse: (state) => {
      state.fetchDashboardPerformanceTableLoading = false;
      state.fetchDashboardPerformanceTableStatus = 'IDLE';
      state.fetchDashboardPerformanceTableData = {};
      state.fetchDashboardPerformanceTableError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardPerformanceTableDataRequest.pending, (state, action) => {
        state.fetchDashboardPerformanceTableLoading = true;
        state.fetchDashboardPerformanceTableStatus = 'PENDING';
      })
      .addCase(getDashboardPerformanceTableDataRequest.fulfilled, (state, action) => {
        state.fetchDashboardPerformanceTableLoading = false;
        state.fetchDashboardPerformanceTableData = action.payload;
        state.fetchDashboardPerformanceTableStatus = 'SUCCESS';
      })
      .addCase(getDashboardPerformanceTableDataRequest.rejected, (state, action) => {
        state.fetchDashboardPerformanceTableLoading = false;
        state.fetchDashboardPerformanceTableError = action.payload;
        state.fetchDashboardPerformanceTableStatus = 'FAILED';
      });
  },
});

export const { clearFetchDashboardPerformanceTableResponse } = getDashboardPerformanceTableDataSlice.actions;

export const getDashboardPerformanceTableDataSelector = (state: RootState) => state.GetDashboardPerformanceTable;

export default getDashboardPerformanceTableDataSlice.reducer;
