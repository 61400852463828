import { VisitCountReportTableProps } from '../../interfaces/commonInterfaces';

import './tableContent.css';

export const VisitCountReportCustomTable: React.FC<VisitCountReportTableProps> = ({
  columns = [],
  dataSource = [],
}) => {
  return (
    <div className="table-content">
      {dataSource?.map((record, recordIndex) => (
        <div key={recordIndex} className="table-content__table-view mb-6">
          <table className="table-content__table">
            <thead className="table-content__table-header">
              <tr>
                <th colSpan={columns.length - 2} className="font-semibold">
                  {record?.mainEntity}
                </th>
                <th colSpan={2} className="text-right pr-4 py-2 font-semibold">
                  Total Count - {record?.totalCount}
                </th>
              </tr>
              <tr>
                {columns?.map((column) => (
                  <th key={column.key} className="text-center">
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="table-content__table-body">
              {record?.data?.map((item: any, itemIndex: number) => (
                <tr key={itemIndex}>
                  {columns.map((column) => (
                    <td key={column.key} className="text-center">
                      {item[column.dataIndex]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};
