import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientPaymentListFetchInterface,
  PatientPaymentListFetchPropsInterface,
} from '../../../interfaces/patientInterface';
import { getPaymentListAPIRequest } from '../../../api/patient/patientTransaction';

const initialState: PatientPaymentListFetchPropsInterface = {
  fetchPaymentListLoading: false,
  fetchPaymentListData: [],
  fetchPaymentListError: null,
  fetchPaymentListStatus: 'IDLE',
};

export const getPaymentListRequest: any = createAsyncThunk(
  'patient/transaction/paymentList',
  async (searchParams: PatientPaymentListFetchInterface, thunkAPI: any) => {
    try {
      const response: any = await getPaymentListAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getPaymentListSlice = createSlice({
  name: 'fetchPaymentList',
  initialState,
  reducers: {
    clearFetchPaymentListResponse: (state) => {
      state.fetchPaymentListLoading = false;
      state.fetchPaymentListStatus = 'IDLE';
      state.fetchPaymentListData = [];
      state.fetchPaymentListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentListRequest.pending, (state, action) => {
        state.fetchPaymentListLoading = true;
        state.fetchPaymentListStatus = 'PENDING';
      })
      .addCase(getPaymentListRequest.fulfilled, (state, action) => {
        state.fetchPaymentListLoading = false;
        state.fetchPaymentListStatus = 'SUCCESS';
        state.fetchPaymentListData = action.payload;
      })
      .addCase(getPaymentListRequest.rejected, (state, action) => {
        state.fetchPaymentListLoading = false;
        state.fetchPaymentListError = action.payload;
        state.fetchPaymentListStatus = 'FAILED';
      });
  },
});

export const { clearFetchPaymentListResponse } = getPaymentListSlice.actions;

export const getPaymentListSelector = (state: RootState) => state.GetPatientPaymentList;

export default getPaymentListSlice.reducer;
