import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { DownloadClaimEraInterface, DownloadClaimEraInitialInterface } from '../../../../interfaces/paymentInterfaces';
import { downloadClaimEraAPIRequest } from '../../../../api/payments/claimEraAutoPost';

const initialState: DownloadClaimEraInitialInterface = {
  downloadClaimEraLoading: false,
  downloadClaimEraData: {},
  downloadClaimEraError: null,
  downloadClaimEraStatus: 'IDLE',
};

export const downloadClaimEraRequest: any = createAsyncThunk(
  'claim-era-auto-post/download-era',
  async (paymentData: DownloadClaimEraInterface, thunkAPI: any) => {
    try {
      const response: any = await downloadClaimEraAPIRequest(paymentData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const downloadClaimEraSlice = createSlice({
  name: 'downloadClaimEra',
  initialState,
  reducers: {
    clearDownloadClaimEraResponse: (state) => {
      state.downloadClaimEraLoading = false;
      state.downloadClaimEraStatus = 'IDLE';
      state.downloadClaimEraData = {};
      state.downloadClaimEraError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadClaimEraRequest.pending, (state, action) => {
        state.downloadClaimEraLoading = true;
        state.downloadClaimEraStatus = 'PENDING';
      })
      .addCase(downloadClaimEraRequest.fulfilled, (state, action) => {
        state.downloadClaimEraData = action.payload;
        state.downloadClaimEraStatus = 'SUCCESS';
        state.downloadClaimEraLoading = false;
      })
      .addCase(downloadClaimEraRequest.rejected, (state, action) => {
        state.downloadClaimEraLoading = false;
        state.downloadClaimEraError = action.payload;
        state.downloadClaimEraStatus = 'FAILED';
      });
  },
});

export const { clearDownloadClaimEraResponse } = downloadClaimEraSlice.actions;

export const downloadClaimEraSelector = (state: RootState) => state.DownloadClaimEra;

export default downloadClaimEraSlice.reducer;
