import { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import moment from 'moment';

import { RightSheetLarge } from '../../../rightSheet/RightSheetLarge';
import { CommonAlert } from '../../../atoms/Alert';
import { TableContent } from '../../../table/TableContent';
import { CommonButton } from '../../../atoms/CommonButton';
import { Spinner } from '../../../atoms/Spinner';
import { EmptyContent } from '../../../emptyContent/EmptyContent';
import { PaymentList } from './PaymentList';
import { AddPayment } from './AddPayment';

import { IoIosInformationCircleOutline } from 'react-icons/io';

import { getPatientsByIdSelector } from '../../../../redux/slices/patient/getPatientByIdSlice';
import {
  addPatientPaymentRequest,
  addPatientPaymentSelector,
  clearAddPatientPaymentResponse,
} from '../../../../redux/slices/patient/addTransactionPaymentSlice';
import {
  getPaymentListRequest,
  getPaymentListSelector,
  clearFetchPaymentListResponse,
} from '../../../../redux/slices/patient/getPaymentListSlice';
import {
  getTransactionsSumamryRequest,
  getTransactionsSumamrySelector,
  clearFetchTransactionsSummaryResponse,
} from '../../../../redux/slices/patient/getTransactionsSummarySlice';
import {
  editTransactionResponsibilityRequest,
  editTransactionResponsibilitySelector,
  clearEditTransactionResponsibility,
} from '../../../../redux/slices/patient/editTransactionResponsibilitySlice';
import {
  getDataForPatientPaymentAddFormRequest,
  getDataForPatientPaymentAddSelector,
  clearFetchDataForPatientPaymentAddResponse,
} from '../../../../redux/slices/patient/getDataForPatientPaymentAddSlice';
import {
  getSystemBillingCodeListRequest,
  getSystemBillingCodeListSelector,
} from '../../../../redux/slices/system/getSystemBillingCodeListSlice';

import { PatientTransactionInterface } from '../../../../interfaces/patientInterface';

import { formatCurrency, formatDate } from '../../../../utils/commonFunctions';
import { createPatientPaymentValidation } from '../../../../utils/patientValidation';

const columns = [
  {
    title: 'Visit ID',
    dataIndex: 'visitId',
    key: 'visitId',
  },
  {
    title: 'Claim ID',
    dataIndex: 'claimId',
    key: 'claimId',
    render: (text: string, record: any) => {
      return record.claimType ? `${text} ${record.claimType}` : text;
    },
  },
  {
    title: 'DOS',
    dataIndex: 'dos',
    key: 'dos',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'CPT',
    dataIndex: 'cpts',
    key: 'cpts',
    render: (text: any, record: any) => {
      return record.cpts ? record.cpts.split(',').join(', ') : '';
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Provider',
    dataIndex: 'providerName',
    key: 'providerName',
  },
  {
    title: 'ICDs',
    dataIndex: 'icds',
    key: 'icds',
    render: (text: any, record: any) => {
      return record.icds ? record.icds.split(',').join(', ') : '';
    },
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    id: '45239 P',
    visitId: '62553',
    visitDate: '12/19/2023',
    facilityId: 'The Rivers of Grosse Pointe',
    referringProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    responsibility: 'Primary',
    date: '12/19/2023',
  },
  {
    id: '45239 P',
    visitId: '62554',
    visitDate: '12/19/2023',
    facilityId: 'The Rivers of Grosse Pointe',
    referringProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    responsibility: '',
    date: '12/19/2023',
  },
  {
    id: '45239 P',
    visitId: '62555',
    visitDate: '12/19/2023',
    facilityId: 'The Rivers of Grosse Pointe',
    referringProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    responsibility: 'Secondary',
    date: '12/19/2023',
  },
  {
    id: '45239 P',
    visitId: '62556',
    visitDate: '12/19/2023',
    facilityId: 'The Rivers of Grosse Pointe',
    referringProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    date: '12/19/2023',
  },
];

const billColumns = [
  {
    title: 'Total Billed',
    dataIndex: 'totalBilled',
    key: 'totalBilled',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Total Payment',
    dataIndex: 'totalPayment',
    key: 'totalPayment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Total Adjustments',
    dataIndex: 'totalAdjustments',
    key: 'totalAdjustments',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Insurance Balance',
    dataIndex: 'insuranceBalance',
    key: 'insuranceBalance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Patient Balance',
    dataIndex: 'patientBalance',
    key: 'patientBalance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Last Statement Sent Date',
    dataIndex: 'lastStatementSentDate',
    key: 'lastStatementSentDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : 'N/A';
    },
  },
  {
    title: 'Total Outstanding Balance',
    dataIndex: 'totalOutStandingBalance',
    key: 'totalOutStandingBalance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const billDataSource = [
  {
    totalBilled: '682.00',
    totalPayment: '256.32',
    totalAdjustments: '171.68',
    insuranceBalanace: '254.00',
    patientBalance: '0.00',
    lastStatementSentDate: 'N/A',
    totalOutStandingBalance: '254.00',
  },
];

const PAYMENT_LIST_PAGE_SIZE = 10;

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const Transaction: React.FC<PatientTransactionInterface> = ({
  mode,
  selectedId,
  fetchDataLoading,
  fetchPatientById,
}) => {
  const dispatch = useAppDispatch();

  const { fetchPatientByIdStatus, fetchPatientByIdData, fetchPatientByIdLoading } =
    useAppSelector(getPatientsByIdSelector);
  const { fetchDataForPatientPaymentAddStatus, fetchDataForPatientPaymentAddData } = useAppSelector(
    getDataForPatientPaymentAddSelector
  );
  const { fetchSystemBillingCodeListData, fetchSystemBillingCodeListStatus } = useAppSelector(
    getSystemBillingCodeListSelector
  );
  const { addTransactionPaymentStatus } = useAppSelector(addPatientPaymentSelector);
  const { fetchPaymentListStatus, fetchPaymentListData } = useAppSelector(getPaymentListSelector);
  const { fetchTransactionSummaryStatus, fetchTransactionSummaryData } = useAppSelector(getTransactionsSumamrySelector);
  const { editTransactionResponsibilityStatus } = useAppSelector(editTransactionResponsibilitySelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [summaryDataSource, setSummaryDataSource] = useState<any[]>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [visibleAddPaymentModel, setVisibleAddPaymentModel] = useState<boolean>(false);
  const [visiblePaymentList, setVisiblePaymentList] = useState<boolean>(false);
  const [paymentListData, setPaymentListData] = useState<any[]>([]);
  const [selectedTransactionId, setSelectedTransactionId] = useState<number | null>(null);
  const [addPaymentFormData, setAddPaymentFormData] = useState<any>({});
  const [whoPaidOptions, setWhoPaidOptions] = useState<{ id: number; name: string }[]>([]);
  const [addPaymentPatientId, setAddPaymentPatientId] = useState<number | null>(null);
  const [billingCodeList, setBillingCodeList] = useState<any[]>([]);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [errorObj, setErrorObj] = useState<any>({});
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [paymentListCurrentPage, setPaymentListCurrentPage] = useState<number>(0);
  const [paymentListtotalPages, setpaymentListtotalPages] = useState<number>(0);

  useEffect(() => {
    fetchTransactionsSummary();
    if (fetchSystemBillingCodeListData?.length === 0) {
      fetchSystemBillingCodes();
    }
  }, []);

  useEffect(() => {
    if (fetchPatientByIdStatus === 'SUCCESS') {
      setDataSource(fetchPatientByIdData);
    }
  }, [fetchPatientByIdStatus]);

  useEffect(() => {
    if (fetchSystemBillingCodeListStatus === 'SUCCESS') {
      const formattedBillingCodes = formatBillingCodes(fetchSystemBillingCodeListData);
      setBillingCodeList(formattedBillingCodes);
    }
  }, [fetchSystemBillingCodeListStatus]);

  useEffect(() => {
    if (addTransactionPaymentStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'transaction');
      fetchTransactionsSummary();
      setVisibleAddPaymentModel(false);
      setAlertObj({
        color: 'success',
        message: 'Payment Added Successfully !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearAddPatientPaymentResponse());
    } else if (addTransactionPaymentStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearAddPatientPaymentResponse());
    }
  }, [addTransactionPaymentStatus]);

  useEffect(() => {
    if (fetchDataForPatientPaymentAddStatus === 'SUCCESS') {
      const { patientId, claimId, whoPaid } = fetchDataForPatientPaymentAddData;

      const formattedWhoPaidOptions = formatWhoPaidOptions(whoPaid);
      setWhoPaidOptions(formattedWhoPaidOptions);

      if (selectedAction === 'ADD') {
        if (claimId === 0) {
          setAlertObj({
            color: 'failure',
            message: 'No claims available',
            error: false,
          });
          setVisibleAlert(true);
          setTimeout(() => {
            setVisibleAlert(false);
            setAlertObj(initialAlertState);
          }, 3000);
        } else {
          setAddPaymentPatientId(patientId);
          setAddPaymentFormData((prev: any) => ({ ...prev, claimId }));

          setVisibleAddPaymentModel(true);
        }
      }

      dispatch(clearFetchDataForPatientPaymentAddResponse());
    } else if (fetchDataForPatientPaymentAddStatus === 'FAILED') {
      if (selectedAction === 'ADD') {
        setVisibleAddPaymentModel(true);
      }
    }
  }, [fetchDataForPatientPaymentAddStatus]);

  useEffect(() => {
    if (fetchPaymentListStatus === 'SUCCESS') {
      setPaymentListData(fetchPaymentListData);
      // setPaymentListCurrentPage(fetchPaymentListData?.currentPage);
      // setpaymentListtotalPages(fetchPaymentListData?.totalPages);
      setVisiblePaymentList(true);
      dispatch(clearFetchPaymentListResponse());
    } else if (fetchPaymentListStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchPaymentListResponse());
    }
  }, [fetchPaymentListStatus]);

  useEffect(() => {
    if (fetchTransactionSummaryStatus === 'SUCCESS') {
      setSummaryDataSource([fetchTransactionSummaryData]);
      dispatch(clearFetchTransactionsSummaryResponse());
    } else if (fetchTransactionSummaryStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong when fetching transaction summary!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchTransactionsSummaryResponse());
    }
  }, [fetchTransactionSummaryStatus]);

  useEffect(() => {
    if (editTransactionResponsibilityStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'transaction');
      fetchTransactionsSummary();
      setAlertObj({
        color: 'success',
        message: 'Responsiiblity successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearEditTransactionResponsibility());
    } else if (editTransactionResponsibilityStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearEditTransactionResponsibility());
    }
  }, [editTransactionResponsibilityStatus]);

  const fetchTransactionsSummary = async () => {
    dispatch(getTransactionsSumamryRequest({ patientId: selectedId }));
  };

  const getPaymentList = async (id = null, pageNumber = paymentListCurrentPage) => {
    dispatch(
      getPaymentListRequest({
        transactionId: id ?? selectedTransactionId,
        page: pageNumber,
        size: PAYMENT_LIST_PAGE_SIZE,
      })
    );
  };

  const getDataForPatientPaymentAddForm = (transactionId: number) => {
    dispatch(getDataForPatientPaymentAddFormRequest({ transactionId }));
  };

  const fetchSystemBillingCodes = () => {
    dispatch(getSystemBillingCodeListRequest());
  };

  const handlePaymentListPageChange = (page: any) => {
    setPaymentListCurrentPage(page - 1);
    getPaymentList(null, page - 1);
  };

  const createErrorAlert = (validationDetails: any) => {
    const errorMessages = Object.values(validationDetails.newErrors);
    return {
      message: (
        <div>
          {errorMessages.map((msg: any, index) => (
            <div key={index} className="flex items-center">
              <IoIosInformationCircleOutline />
              <span className="ml-2">{msg}</span>
            </div>
          ))}
        </div>
      ),
      color: 'failure',
      error: true,
    };
  };

  const convertBeforeSubmit = (paymentData: any) => {
    const obj = {
      ...paymentData,
      whoPaid: parseInt(paymentData?.whoPaid, 10),
      allowedAmount: paymentData?.allowedAmount ? Number(parseFloat(paymentData?.allowedAmount).toFixed(2)) : undefined,
      amount: paymentData?.amount ? Number(parseFloat(paymentData?.amount).toFixed(2)) : undefined,
      paymentDate: formatDate(paymentData?.paymentDate),
      bankPostingDate: formatDate(paymentData?.bankPostingDate),
    };

    return obj;
  };

  const handleAddPayment = () => {
    const validationDetails = createPatientPaymentValidation(addPaymentFormData);

    if (Object.keys(validationDetails?.newErrors)?.length > 0) {
      setAlertObj(createErrorAlert(validationDetails));
      setVisibleAlert(true);
    } else {
      const obj = {
        patientId: addPaymentPatientId,
        paymentData: convertBeforeSubmit(addPaymentFormData),
      };

      dispatch(addPatientPaymentRequest(obj));
    }
  };

  const formatWhoPaidOptions = (whoPaid: any[] = []) =>
    whoPaid?.map((item) => ({
      id: item.id,
      name: item.name,
    }));

  const formatBillingCodes = (codes: any[]) => {
    return codes.map((code) => ({
      id: code.id,
      name: `${code.code ? code.code + ' - ' : ''}${code.name}`,
    }));
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    // const dataSet = dataSource[rowIndex];
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    // setMode('EDIT');
    // setSelectedToEdit(dataSet);
    // setVisibleEditForm(true);
  };

  const onAdd = (event: any, rowIndex: any) => {
    event.preventDefault();
    setSelectedAction('ADD');
    const dataSet = dataSource[rowIndex];
    setSelectedTransactionId(dataSet?.id);
    setAddPaymentFormData({ visitCptId: dataSet?.id, visitId: dataSet?.visitId, isComplete: false });
    getDataForPatientPaymentAddForm(dataSet?.id);
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
    setSelectedAction('VIEW');
    const dataSet = dataSource[rowIndex];
    setSelectedTransactionId(dataSet?.id);
    getPaymentList(dataSet?.id);
    getDataForPatientPaymentAddForm(dataSet?.id);
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const onSubmit = () => {
    // addressFormData['state'] = 'FL';
    // addressFormData['country'] = 'US';
    // let obj = {
    //   userId: selectedId,
    //   addressData: addressFormData,
    // };
    // dispatch(editAddressRequest(obj));
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  const handleAddNewModal = () => {
    setVisibleModal(true);
  };

  const onCloseModal = () => {
    setVisibleModal(false);
  };

  const handleOnClosePaymentList = () => {
    setVisiblePaymentList(false);
    setPaymentListData([]);
    setSelectedTransactionId(null);
  };

  const handleOnCloseAddPaymentModel = () => {
    setAddPaymentFormData({});
    setSelectedTransactionId(null);
    setVisibleAlert(false);
    setErrorObj({});
    setAlertObj(initialAlertState);
    setVisibleAddPaymentModel(false);
  };

  const handleResponsibilityChange = (responsibilityId: any, rowIndex: any) => {
    // console.log('CLICKED ID', responsibilityId);
    const dataset = dataSource[rowIndex];
    // console.log('Dataset', dataset);

    dispatch(
      editTransactionResponsibilityRequest({
        transactionId: dataset?.id,
        responsibility: responsibilityId,
      })
    );
  };

  const handlePaymentFormChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }

    const convertedValue = type === 'checkbox' ? checked : value;

    setAddPaymentFormData((prev: any) => ({
      ...prev,
      [name]: convertedValue,
    }));
  };

  const handlePaymentDateChange = (name: string, value: Date) => {
    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }
    setAddPaymentFormData((prev: any) => ({
      ...prev,
      [name]: moment(value).format('YYYY-MM-DD'),
    }));
  };

  const handleBillingCodeChange = (e: any) => {
    const selectedCode = fetchSystemBillingCodeListData?.find((code: any) => code.id === Number(e.target.value));

    setAddPaymentFormData((prev: any) => ({
      ...prev,
      billingCodeType: selectedCode ? selectedCode?.type : '',
      billingCode: selectedCode ? selectedCode?.id : '',
    }));
  };

  // console.log('selectedTransactionId', selectedTransactionId);
  // console.log('addPaymentFormData', addPaymentFormData);
  // console.log('form data', addPaymentFormData);
  return (
    <>
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <div className=" flex w-3/6 ml-auto space-x-3 my-2">
        {/* {mode !== 'VIEW' && (
          <CommonButton
            label="Add New"
            buttonType="primary"
            disabled
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            onClick={handleAddNewModal}
          />
        )} */}
        {mode !== 'VIEW' && (
          <CommonButton
            label="Walkout Receipt"
            buttonType="primary"
            // onClick={handleAddNewModal}
          />
        )}
        {mode !== 'VIEW' && (
          <CommonButton
            label="Print Statement"
            buttonType="primary"
            // onClick={handleAddNewModal}
          />
        )}
        {mode !== 'VIEW' && (
          <CommonButton
            label="Send Statement"
            buttonType="primary"
            // onClick={handleAddNewModal}
          />
        )}
      </div>
      {fetchPatientByIdLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource && dataSource?.length > 0 ? (
            <>
              <TableContent
                enableActions={true}
                columns={columns}
                dataSource={dataSource}
                enableAdd={mode === 'EDIT'}
                enableView={true}
                enableResponsibilityButton={true}
                onView={onView}
                onHandleAdd={onAdd}
                mode={mode}
                onHandleResponsibility={handleResponsibilityChange}
              />

              <TableContent
                enableActions={false}
                columns={billColumns}
                dataSource={summaryDataSource}
                enableDelete={false}
                enableEdit={false}
                enableView={false}
              />
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}

      {visibleAddPaymentModel && (
        <AddPayment
          visibleAddPaymentModel={visibleAddPaymentModel}
          handleOnClose={handleOnCloseAddPaymentModel}
          addPaymentFormData={addPaymentFormData}
          billingCodesList={billingCodeList}
          whoPaidList={whoPaidOptions}
          onChange={handlePaymentFormChange}
          onDateChange={handlePaymentDateChange}
          onBillingCodeChange={handleBillingCodeChange}
          onSubmit={handleAddPayment}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          handleAlertClose={handleAlertClose}
        />
      )}

      {visiblePaymentList && (
        <RightSheetLarge
          title="Payment/Adjustment List"
          children={
            <PaymentList
              dataSource={paymentListData}
              onPageChange={handlePaymentListPageChange}
              totalPages={paymentListtotalPages}
              currentPage={paymentListCurrentPage}
              fetchPatientById={fetchPatientById}
              fetchPaymentList={getPaymentList}
              selectedId={selectedId}
              setVisiblePaymentList={setVisiblePaymentList}
              billingCodeList={billingCodeList}
              whoPaidList={whoPaidOptions}
              mode={mode}
            />
          }
          enableStepper={false}
          enableFooterButtons={false}
          onClose={handleOnClosePaymentList}
        />
      )}
    </>
  );
};
