import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  FetchClaimEraFilesInterface,
  FetchClaimEraFilesByReceivedDateInitialInterface,
} from '../../../../interfaces/paymentInterfaces';
import { getClaimEraFilesByReceivedDateAPIRequest } from '../../../../api/payments/claimEraFiles';

const initialState: FetchClaimEraFilesByReceivedDateInitialInterface = {
  fetchClaimEraFilesByReceivedDateLoading: false,
  fetchClaimEraFilesByReceivedDateData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
    totalEra: 0,
  },
  fetchClaimEraFilesByReceivedDateError: null,
  fetchClaimEraFilesByReceivedDateStatus: 'IDLE',
};

export const getClaimEraFilesByReceivedDateRequest: any = createAsyncThunk(
  'claim-era-files-by-received-date/get',
  async (searchParams: FetchClaimEraFilesInterface, thunkAPI: any) => {
    try {
      const response: any = await getClaimEraFilesByReceivedDateAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getClaimEraFilesByReceivedDateSlice = createSlice({
  name: 'fetchClaimEraFilesByReceivedDate',
  initialState,
  reducers: {
    clearFetchClaimEraFilesByReceivedDateResponse: (state) => {
      state.fetchClaimEraFilesByReceivedDateLoading = false;
      state.fetchClaimEraFilesByReceivedDateStatus = 'IDLE';
      state.fetchClaimEraFilesByReceivedDateData = {
        currentPage: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
        totalEra: 0,
      };
      state.fetchClaimEraFilesByReceivedDateError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimEraFilesByReceivedDateRequest.pending, (state, action) => {
        state.fetchClaimEraFilesByReceivedDateLoading = true;
        state.fetchClaimEraFilesByReceivedDateStatus = 'PENDING';
      })
      .addCase(getClaimEraFilesByReceivedDateRequest.fulfilled, (state, action) => {
        state.fetchClaimEraFilesByReceivedDateLoading = false;
        state.fetchClaimEraFilesByReceivedDateStatus = 'SUCCESS';
        state.fetchClaimEraFilesByReceivedDateData = action.payload;
      })
      .addCase(getClaimEraFilesByReceivedDateRequest.rejected, (state, action) => {
        state.fetchClaimEraFilesByReceivedDateLoading = false;
        state.fetchClaimEraFilesByReceivedDateError = action.payload;
        state.fetchClaimEraFilesByReceivedDateStatus = 'FAILED';
      });
  },
});

export const { clearFetchClaimEraFilesByReceivedDateResponse } = getClaimEraFilesByReceivedDateSlice.actions;

export const getClaimEraFilesByReceivedDateSelector = (state: RootState) => state.GetClaimEraFilesByReceivedDate;

export default getClaimEraFilesByReceivedDateSlice.reducer;
