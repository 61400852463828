import { Modal } from 'flowbite-react';
import { TableContent } from '../../table/TableContent';
import { CommonButton } from '../../atoms/CommonButton';

import { formatCurrency } from '../../../utils/commonFunctions';

const columns = [
  {
    title: 'Patient ID',
    dataIndex: 'patientId',
    key: 'patientId',
  },
  {
    title: 'Patient',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Check #',
    dataIndex: 'checkNumber',
    key: 'checkNumber',
  },
];

const dummyDataSource = {
  items: [
    {
      patientId: 1232,
      patientName: 'Carl Switzer',
      amount: 232.43,
      check: '3232324',
    },
    {
      patientId: 542,
      patientName: 'Judith Kemper',
      amount: 22,
      check: '8789',
    },
    {
      patientId: 678,
      patientName: 'Connie Selman',
      amount: 12.43,
      check: '43546',
    },
  ],
  total: 266.86,
};

export const BatchView = ({ openBatchView, setOpenBatchView, dataSource, total }: any) => {
  return (
    <Modal show={openBatchView} onClose={setOpenBatchView}>
      <Modal.Header>Batch Payments</Modal.Header>
      <Modal.Body>
        {dataSource && dataSource?.length > 0 ? (
          <>
            <TableContent enableActions={false} columns={columns} dataSource={dataSource} />
            <div className="flex justify-center mt-4">
              <span className="font-semibold">Total: {formatCurrency(total)}</span>
            </div>
          </>
        ) : (
          <div className="text-center my-2 text-gray6 font-semibold">No Transactions Found!</div>
        )}
      </Modal.Body>
      <Modal.Footer className="flex justify-end">
        <CommonButton
          type="button"
          label={'Close'}
          buttonType="secondary"
          style={{ width: '80px' }}
          onClick={() => setOpenBatchView(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};
