import {
  FetchClaimEraAutoPostInterface,
  PostClaimEraPaymentInterface,
  DownloadClaimEraInterface,
} from '../../interfaces/paymentInterfaces';
import { authenticatedRequest } from '../../utils/commonAxios';

export const getClaimEraAutoPostAPIRequest = async (searchParams: FetchClaimEraAutoPostInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/payment/claimmd-era-post/search?page=${searchParams?.page}&size=${searchParams?.size}`,
    'post',
    {
      data: searchParams?.filters ?? {},
    }
  );
};

export const postClaimEraPaymentAPIRequest = async (paymentData: PostClaimEraPaymentInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/payment/claimmd-era-post/${paymentData?.claimEraId}`,
    'put',
    {
      data: { ...paymentData?.claimEraData },
    }
  );
};

export const downloadClaimEraAPIRequest = async (searchData: DownloadClaimEraInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/payment/claimmd-era/download-era`, 'post', {
    data: { ...searchData },
  });
};
