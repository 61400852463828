import { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { ClaimERAAutoPostHeader } from '../../../components/billing/claimERAAutoPost/ClaimERAAutoPostHeader';
import { DownloadERAModal } from '../../../components/billing/claimERAAutoPost/DownloadERAModal';
import { SelectInput } from '../../../components/atoms/SelectInput';
import { CommonAlert } from '../../../components/atoms/Alert';
import { Spinner } from '../../../components/atoms/Spinner';
import { Checkbox } from '../../../components/atoms/Checkbox';
import { DatePickerField } from '../../../components/atoms/DatePickerField';

import {
  getClaimEraAutoPostRequest,
  getClaimEraAutoPostSelector,
  clearFetchClaimEraAutoPostResponse,
} from '../../../redux/slices/payments/claimEraAutoPost/getClaimEraAutoPostSlice';
import {
  postClaimEraPaymentRequest,
  postClaimEraPaymentSelector,
  clearPostClaimEraPaymentResponse,
} from '../../../redux/slices/payments/claimEraAutoPost/postClaimEraPaymentSlice';
import {
  downloadClaimEraRequest,
  downloadClaimEraSelector,
  clearDownloadClaimEraResponse,
} from '../../../redux/slices/payments/claimEraAutoPost/downloadClaimEraSlice';

import { bankTransactionType } from '../../../constants/billingConstants';

import { formatDatePickerDate } from '../../../utils/commonFunctions';
import { validDateFormat } from '../../../utils/commonFunctions';
import { formatCurrency } from '../../../utils/commonFunctions';

const PAGE_SIZE = 10;

const columns = (
  claimEraData: any,
  handleBankTransactionTypeChange: any,
  handleBankPostingDate: any,
  handleisFwd: any
) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Received Date',
    dataIndex: 'receivedDate',
    key: 'receivedDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'Paid Date',
    dataIndex: 'paidDate',
    key: 'paidDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'ERA ID',
    dataIndex: 'eraID',
    key: 'eraID',
  },
  {
    title: 'Payer Name',
    dataIndex: 'payerName',
    key: 'payerName',
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNumber',
    key: 'checkNumber',
  },
  {
    title: 'Paid Amount',
    dataIndex: 'paidAmount',
    key: 'paidAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Bank Posting Date',
    dataIndex: 'bankPostingDate',
    key: 'bankPostingDate',
    render: (text: any, record: any) => (
      <DatePickerField
        name="bankPostingDate"
        selectedDate={formatDatePickerDate(claimEraData?.[record?.id]?.bankPostingDate)}
        onChange={(date: Date | null) => {
          if (date) {
            handleBankPostingDate(date, record?.id);
          }
        }}
      />
    ),
  },
  {
    title: 'Bank Transaction Type',
    dataIndex: 'bankTransactionType',
    key: 'bankTransactionType',
    render: (text: any, record: any) => (
      <SelectInput
        options={bankTransactionType}
        enableDefaultPlaceholder={true}
        name="bankTransactionType"
        value={claimEraData?.[record?.id]?.bankTransactionType ?? ''}
        onChange={(e: any) => handleBankTransactionTypeChange(e.target.value, record?.id)}
      />
    ),
  },
  {
    title: 'Is Fwd',
    dataIndex: 'fwd',
    key: 'fwd',
    render: (text: any, record: any) => (
      <Checkbox
        onChange={(e: any) => handleisFwd(e.target.checked, record?.id)}
        checked={claimEraData?.[record?.id]?.fwd || false}
      />
    ),
  },
];

const dummyDataSource = [
  {
    id: '1',
    receivedDate: '2024-05-01',
    paidDate: '2024-05-02',
    eraId: 'ERA123456',
    groupName: 'Physicians Care Solutions',
    checkNumber: '12345',
    paidAmount: 3216.4,
    bankPostingDate: '2024-05-03',
    bankTransactionType: 'EFT',
    isFwd: false,
  },
];

export const months = [{ id: 'May', name: 'May' }];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'claimAutoPost', label: 'Claim ERA Auto Post', status: 'active', link: 'claim-auto-post' },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const ClaimERAAutoPostPage = () => {
  const dispatch = useAppDispatch();

  const { fetchClaimEraAutoPostStatus, fetchClaimEraAutoPostData, fetchClaimEraAutoPostLoading } =
    useAppSelector(getClaimEraAutoPostSelector);
  const { postClaimEraPaymentStatus, postClaimEraPaymentError } = useAppSelector(postClaimEraPaymentSelector);
  const { downloadClaimEraStatus, downloadClaimEraData, downloadClaimEraError } =
    useAppSelector(downloadClaimEraSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({});
  const [claimEraData, setClaimEraData] = useState<any>({});
  const [postDisabledRows, setPostDisabledRows] = useState<Set<number>>(new Set());

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);
  const [visibleDownloadERA, setVisibleDownloadERA] = useState<boolean>(false);

  useEffect(() => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchClaimEraAutoPost();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchClaimEraAutoPostStatus === 'SUCCESS') {
      setDataSource(fetchClaimEraAutoPostData?.items);
      setCurrentPage(fetchClaimEraAutoPostData?.currentPage);
      setTotalPages(fetchClaimEraAutoPostData?.totalPages);
      dispatch(clearFetchClaimEraAutoPostResponse());
    } else if (fetchClaimEraAutoPostStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchClaimEraAutoPostResponse());
    }
  }, [fetchClaimEraAutoPostStatus]);

  useEffect(() => {
    if (postClaimEraPaymentStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully posted !',
        error: true,
      });
      setVisibleAlert(true);
      fetchClaimEraAutoPost();
      setClaimEraData({});
      setPostDisabledRows(new Set());
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearPostClaimEraPaymentResponse());
    } else if (postClaimEraPaymentStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: postClaimEraPaymentError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearPostClaimEraPaymentResponse());
    }
  }, [postClaimEraPaymentStatus]);

  useEffect(() => {
    if (downloadClaimEraStatus === 'SUCCESS') {
      setVisibleDownloadERA(false);
      setAlertObj({
        color: 'success',
        message: downloadClaimEraData?.message ?? 'Successfully posted !',
        error: true,
      });
      setVisibleAlert(true);
      fetchClaimEraAutoPost();
      setClaimEraData({});
      setPostDisabledRows(new Set());
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearDownloadClaimEraResponse());
    } else if (downloadClaimEraStatus === 'FAILED') {
      setVisibleDownloadERA(false);
      setAlertObj({
        color: 'failure',
        message: downloadClaimEraError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearDownloadClaimEraResponse());
    }
  }, [downloadClaimEraStatus]);

  useEffect(() => {
    const newDisabledRows = new Set<number>();
    dataSource?.forEach((row, rowIndex) => {
      const claimEraRecordData = claimEraData?.[row.id];
      if (!isClaimEraRecordComplete(claimEraRecordData)) {
        newDisabledRows.add(rowIndex);
      }
    });
    setPostDisabledRows(newDisabledRows);
  }, [dataSource, claimEraData]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchClaimEraAutoPost(page - 1);
  };

  const fetchClaimEraAutoPost = async (pageNumber = currentPage) => {
    dispatch(getClaimEraAutoPostRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.checkNumber || updatedData?.checkNumber === '') {
      delete updatedData.checkNumber;
    }

    setClaimEraData({});
    setPostDisabledRows(new Set());

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleDownload = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];

    if (dataSet?.claimPDF) {
      window.open(dataSet.claimPDF, '_blank');
    }
  };

  const isClaimEraRecordComplete = (claimEraRecordData: any) => {
    if (!claimEraRecordData) return false;

    const requiredFields = ['bankTransactionType', 'bankPostingDate', 'fwd'];
    return requiredFields.every(
      (field) =>
        claimEraRecordData[field] !== undefined &&
        claimEraRecordData[field] !== null &&
        claimEraRecordData[field] !== ''
    );
  };

  const handlePostClicked = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];

    const claimEraRecordData = claimEraData[dataSet?.id];

    if (claimEraRecordData && isClaimEraRecordComplete(claimEraRecordData)) {
      const obj = {
        claimEraId: dataSet?.id,
        claimEraData: {
          bankPostingDate: claimEraRecordData.bankPostingDate,
          btType: claimEraRecordData.bankTransactionType,
          fwd: claimEraRecordData.fwd,
        },
      };

      dispatch(postClaimEraPaymentRequest(obj));
    }
  };

  const handleSubmitDownloadERA = (data: any) => {
    dispatch(downloadClaimEraRequest(data));
  };

  const onClickDownloadERA = () => {
    setVisibleDownloadERA(true);
  };

  const onCloseDownloadERA = () => {
    setVisibleDownloadERA(false);
  };

  const handleBankTransactionTypeChange = (value: any, id: any) => {
    setClaimEraData((prev: any) => ({
      ...prev,
      [id]: {
        ...prev[id],
        bankTransactionType: value,
        fwd: prev[id]?.fwd ?? false,
      },
    }));
  };

  const handleBankPostingDate = (date: any, id: any) => {
    setClaimEraData((prev: any) => ({
      ...prev,
      [id]: {
        ...prev[id],
        bankPostingDate: moment(date).format('YYYY-MM-DD'),
        fwd: prev[id]?.fwd ?? false,
      },
    }));
  };

  const handleisFwd = (value: any, id: any) => {
    setClaimEraData((prev: any) => ({
      ...prev,
      [id]: {
        ...prev[id],
        fwd: value,
      },
    }));
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Claim ERA Auto Post" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <ClaimERAAutoPostHeader onDownload={onClickDownloadERA} onSubmit={handleSearch} />
      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <div className="flex justify-end">
        <TableSearch />
      </div>
      {fetchClaimEraAutoPostLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <TableContent
                  enableActions={true}
                  columns={columns(claimEraData, handleBankTransactionTypeChange, handleBankPostingDate, handleisFwd)}
                  dataSource={dataSource}
                  enableDownload={true}
                  enablePrimaryButton={true}
                  primaryButtonLabel="Post"
                  onHandleDownload={handleDownload}
                  onClickPrimaryButton={handlePostClicked}
                  primaryButtonDisabledRows={postDisabledRows}
                />
              </div>
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}

      {visibleDownloadERA && (
        <DownloadERAModal
          openDownloadERA={visibleDownloadERA}
          setOpenDownloadERA={onCloseDownloadERA}
          onSubmit={handleSubmitDownloadERA}
        />
      )}
    </div>
  );
};
