import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientPaymentUpdateInterface,
  PatientPaymentUpdatePropsInterface,
} from '../../../interfaces/patientInterface';
import { editPatientPaymentAPIRequest } from '../../../api/patient/patientTransaction';

const initialState: PatientPaymentUpdatePropsInterface = {
  editTransactionPaymentLoading: false,
  editTransactionPaymentData: {},
  editTransactionPaymentStatus: 'IDLE',
  editTransactionPaymentError: null,
};

export const editPatientPaymentRequest: any = createAsyncThunk(
  'patient/transaction/paymentEdit',
  async (payment: PatientPaymentUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await editPatientPaymentAPIRequest(payment);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const editPatientPaymentSlice = createSlice({
  name: 'editPatientPayment',
  initialState,
  reducers: {
    clearEditPatientPaymentResponse: (state) => {
      state.editTransactionPaymentLoading = false;
      state.editTransactionPaymentData = {};
      state.editTransactionPaymentStatus = 'IDLE';
      state.editTransactionPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editPatientPaymentRequest.pending, (state, action) => {
        state.editTransactionPaymentLoading = true;
        state.editTransactionPaymentStatus = 'PENDING';
      })
      .addCase(editPatientPaymentRequest.fulfilled, (state, action) => {
        state.editTransactionPaymentLoading = false;
        state.editTransactionPaymentStatus = 'SUCCESS';
        state.editTransactionPaymentData = action.payload;
      })
      .addCase(editPatientPaymentRequest.rejected, (state, action) => {
        state.editTransactionPaymentLoading = false;
        state.editTransactionPaymentError = action.payload;
        state.editTransactionPaymentStatus = 'FAILED';
      });
  },
});

export const { clearEditPatientPaymentResponse } = editPatientPaymentSlice.actions;

export const editPatientPaymentSelector = (state: RootState) => state.EditPatientPayment;

export default editPatientPaymentSlice.reducer;
