export const batchType = [
  { id: 'EOB', name: 'EOB' },
  { id: 'LOCKBOX', name: 'Lock Box' },
];

export const bankTransactionType = [
  { id: 'EFT', name: 'EFT' },
  { id: 'LOCKBOX', name: 'Lock Box' },
];

export const patientStatementsStatus = [
  { id: 'READY_TO_SEND', name: 'Ready to Send' },
  { id: 'SENT', name: 'Sent' },
  { id: 'INACTIVE', name: 'Inactive' },
  { id: 'PAYMENT_PLAN', name: 'Payment Plan' },
  { id: 'WRITE_OFF_REQUIRED', name: 'Write Off Required' },
  { id: 'FINAL_NOTICE', name: 'Final Notice' },
  { id: 'ALL', name: 'All Status' },
];

export const claimFormEditJson = {
  payerId: '',
  showPayerAddress: true,
  insuredIdNumber: '',
  payerAddress1: '',
  payerAddress2: '',
  payerCity: '',
  payerState: '',
  payerZip: '',
  payerOfficeNumber: '',
  patientFName: '',
  patientMName: '',
  patientLName: '',
  patientDob: '',
  patientGender: '',
  patientAddress1: '',
  patientAddress2: '',
  patientCity: '',
  patientState: '',
  patientZip: '',
  patientExtn: '',
  patientMaritalStatus: '',
  patientEmploymentStatus: '',
  insuredFName: '',
  insuredMName: '',
  insuredLName: '',
  patientRelationship: '',
  insuredAddress1: '',
  insuredAddress2: '',
  insuredCity: '',
  insuredState: '',
  insuredZip: '',
  insuredExtn: '',
  insuredPolicyGroup: '',
  insuredDob: '',
  insuredSex: '',
  otherInsuredFName: '',
  otherInsuredMName: '',
  otherInsuredLName: '',
  otherInsuredPolicy: '',
  otherInsuredDob: '',
  otherInsuredSex: '',
  otherPayerId: '',
  otherPayerClaimId: '',
  otherPayerAddress1: '',
  otherPayerAddress2: '',
  otherPayerCity: '',
  otherPayerState: '',
  otherPayerZip: '',
  otherPatientRelationship: '',
  otherPayerGroupName: '',
  otherPayerGroupNumber: '',
  patientOtherAccident: '',
  primaryPaymentDate: '',
  patientEmployment: '',
  patientAutoAccident: '',
  patientAccidentState: '',
  insuredEmployerName: '',
  insurancePlanName: '',
  dateOfCondition: '',
  illnessIndicator: '',
  referringPhysicianFName: '',
  referringPhysicianMName: '',
  referringPhysicianLName: '',
  referringPhysicianNpi: '',
  referringPhysicianOtherId: '',
  claimNarrative: '',
  hospDateFrom: '',
  hospDateTo: '',
  resubmissionCode: '',
  priorAuthorizationNumber: '',
  taxId: '',
  type: '',
  patientAcct: '',
  acceptAssign: '',
  totalCharge: '',
  amountPaid: '',
  balance: '',
  renderingProviderId: '',
  renderingProviderFName: '',
  renderingProviderMName: '',
  renderingProviderLName: '',
  renderingProviderTaxonomy: '',
  renderingProviderNpi: '',
  facilityId: '',
  facilityNpi: '',
  billingProviderName: '',
  billingProviderAddress1: '',
  billingProviderAddress2: '',
  billingProviderCity: '',
  billingProviderState: '',
  billingProviderZip: '',
  billingProviderPhone: '',
  billingProviderTaxonomy: '',
  billingProviderNpi: '',
  billingProviderBillId: '',
};

export const claimPayerJson = {
  payerId: '',
  payerName: '',
  showPayerAddress: true,
  insuredIdNumber: '',
  payerAddress1: '',
  payerAddress2: '',
  payerCity: '',
  payerState: '',
  payerZip: '',
  payerOfficeNumber: '',
};

export const claimOtherPayerJson = {
  otherPayerId: '',
  otherPayerClaimId: '',
  otherPayerAddress1: '',
  otherPayerAddress2: '',
  otherPayerCity: '',
  otherPayerState: '',
  otherPayerZip: '',
};

export const claimEditPostJson: any = {
  payerId: null,
  showPayerAddress: true,
  insuredIdNumber: null,
  payerAddress1: '',
  payerAddress2: '',
  payerCity: '',
  payerState: '',
  payerZip: '',
  payerOfficeNumber: '',
  patientFName: '',
  patientMName: '',
  patientLName: '',
  patientDob: '',
  patientGender: '',
  patientAddress1: '',
  patientAddress2: '',
  patientCity: '',
  patientState: '',
  patientZip: '',
  patientExtn: '',
  patientMaritalStatus: '',
  patientEmploymentStatus: '',
  insuredFName: '',
  insuredMName: '',
  insuredLName: '',
  patientRelationship: '',
  insuredAddress1: '',
  insuredAddress2: '',
  insuredCity: '',
  insuredState: '',
  insuredZip: '',
  insuredExtn: '',
  insuredPolicyGroup: '',
  insuredDob: '',
  insuredSex: '',
  otherInsuredFName: '',
  otherInsuredMName: '',
  otherInsuredLName: '',
  otherInsuredPolicy: '',
  otherInsuredDob: '',
  otherInsuredSex: '',
  otherPayerId: null,
  otherPayerClaimId: null,
  otherPayerAddress1: '',
  otherPayerAddress2: '',
  otherPayerCity: '',
  otherPayerState: '',
  otherPayerZip: '',
  otherPatientRelationship: '',
  otherPayerGroupName: '',
  otherPayerGroupNumber: '',
  patientOtherAccident: '',
  primaryPaymentDate: '',
  patientEmployment: '',
  patientAutoAccident: '',
  patientAccidentState: '',
  insuredEmployerName: '',
  insurancePlanName: '',
  dateOfCondition: '',
  illnessIndicator: '',
  referringPhysicianFName: '',
  referringPhysicianMName: '',
  referringPhysicianLName: '',
  referringPhysicianNpi: null,
  referringPhysicianOtherId: null,
  claimNarrative: '',
  hospDateFrom: '',
  hospDateTo: '',
  resubmissionCode: '',
  priorAuthorizationNumber: null,
  taxId: null,
  type: '',
  patientAcct: null,
  acceptAssign: '',
  totalCharge: null,
  amountPaid: null,
  balance: null,
  renderingProviderId: null,
  renderingProviderFName: '',
  renderingProviderMName: '',
  renderingProviderLName: '',
  renderingProviderTaxonomy: '',
  renderingProviderNpi: null,
  facilityId: null,
  facilityNpi: null,
  billingProviderName: '',
  billingProviderAddress1: '',
  billingProviderAddress2: '',
  billingProviderCity: '',
  billingProviderState: '',
  billingProviderZip: '',
  billingProviderPhone: '',
  billingProviderTaxonomy: '',
  billingProviderNpi: null,
  billingProviderBillId: null,
};
