import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  AddBulkLinePaymentsInterface,
  AddBulkLinePaymentsInitialInterface,
} from '../../../../interfaces/paymentInterfaces';
import { addBulkLinePaymentsAPIRequest } from '../../../../api/payments/batchPayments';

const initialState: AddBulkLinePaymentsInitialInterface = {
  addBulkLinePaymentsLoading: false,
  addBulkLinePaymentsData: {},
  addBulkLinePaymentsError: null,
  addBulkLinePaymentsStatus: 'IDLE',
};

export const addBulkLinePaymentsRequest: any = createAsyncThunk(
  'batch-payment/bulk-line-payments/add',
  async (paymentsData: AddBulkLinePaymentsInterface, thunkAPI: any) => {
    try {
      const response: any = await addBulkLinePaymentsAPIRequest(paymentsData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addBulkLinePaymentsSlice = createSlice({
  name: 'addBulkLinePayments',
  initialState,
  reducers: {
    clearAddBulkLinePaymentsResponse: (state) => {
      state.addBulkLinePaymentsLoading = false;
      state.addBulkLinePaymentsStatus = 'IDLE';
      state.addBulkLinePaymentsData = {};
      state.addBulkLinePaymentsError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBulkLinePaymentsRequest.pending, (state, action) => {
        state.addBulkLinePaymentsLoading = true;
        state.addBulkLinePaymentsStatus = 'PENDING';
      })
      .addCase(addBulkLinePaymentsRequest.fulfilled, (state, action) => {
        state.addBulkLinePaymentsData = action.payload;
        state.addBulkLinePaymentsStatus = 'SUCCESS';
        state.addBulkLinePaymentsLoading = false;
      })
      .addCase(addBulkLinePaymentsRequest.rejected, (state, action) => {
        state.addBulkLinePaymentsLoading = false;
        state.addBulkLinePaymentsError = action.payload;
        state.addBulkLinePaymentsStatus = 'FAILED';
      });
  },
});

export const { clearAddBulkLinePaymentsResponse } = addBulkLinePaymentsSlice.actions;

export const addBulkLinePaymentsSelector = (state: RootState) => state.AddBulkLinePayments;

export default addBulkLinePaymentsSlice.reducer;
