import { authenticatedRequest } from '../../utils/commonAxios';

export const getDenialCodeListAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/denial-codes`, 'get', {});
};

export const getRemarkCodeListAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/remark-codes`, 'get', {});
};

export const getSystemBillingCodeListAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/billing-codes`, 'get', {
    data: {},
  });
};
