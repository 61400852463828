import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import moment from 'moment';

import { InputField } from '../../../components/atoms/InputField';
import { PrimaryButton } from '../../../components/atoms/PrimaryButton';
import { CommonButton } from '../../../components/atoms/CommonButton';
import { CustomSearchButton } from '../../../components/list/patients/visit/AddCPTModal';
import { SelectInput } from '../../../components/atoms/SelectInput';
import { CommonAlert } from '../../../components/atoms/Alert';
import { Spinner } from '../../../components/atoms/Spinner';
import { BulkPaymentsCustomTable } from '../../../components/table/BulkPaymentsCustomTable';
import { PatientSearchModal } from '../../../components/billing/batchPayment/bulkPayments/PatientSearchModal';
import { AddDescriptionModal } from '../../../components/billing/batchPayment/bulkPayments/AddDescriptionModal';

import {
  getBulkPaymentsListRequest,
  getBulkPaymentsListSelector,
  clearFetchBulkPaymentsListResponse,
} from '../../../redux/slices/payments/batchPayments/getBulkPaymentsListSlice';
import {
  getSystemBillingCodeListRequest,
  getSystemBillingCodeListSelector,
  clearFetchSystemBillingCodesResponse,
} from '../../../redux/slices/system/getSystemBillingCodeListSlice';
import {
  addBulkLinePaymentsRequest,
  addBulkLinePaymentsSelector,
  clearAddBulkLinePaymentsResponse,
} from '../../../redux/slices/payments/batchPayments/addBulkLinePaymentsSlice';

import { BulkPaymentsPropsInterface } from '../../../interfaces/paymentInterfaces';

import { formatCurrency } from '../../../utils/commonFunctions';

const columns = (billingCodes: any, handleBillingCodeChange: any, handleRowDataChange: any) => [
  {
    title: 'DOS',
    dataIndex: 'dos',
    key: 'dos',
    render: (text: string, record: any) => {
      return record.newRow ? <></> : moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'CPT',
    dataIndex: 'cpt',
    key: 'cpt',
  },
  {
    title: 'Units',
    dataIndex: 'unit',
    key: 'unit',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (text: number, record: any) => {
      return record.newRow ? <></> : formatCurrency(text ?? 0);
    },
  },
  {
    title: 'Modifier',
    dataIndex: 'modifier',
    key: 'modifier',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number, record: any) => {
      return record.newRow ? <></> : formatCurrency(text ?? 0);
    },
  },
  {
    title: 'Billing Code',
    dataIndex: 'billingCode',
    key: 'billingCode',
    render: (text: any, record: any) => (
      <SelectInput
        options={billingCodes}
        enableDefaultPlaceholder={true}
        name="billingCode"
        // defaultValue={record?.billingCode ?? undefined}
        enableLabel={false}
        onChange={(e: any) => handleBillingCodeChange(record?.patientVisitCptId, e.target.value)}
        disabled={record?.claim === 0}
      />
    ),
  },
  {
    title: 'Claim ID',
    dataIndex: 'claim',
    key: 'claim',
    // render: (text: any, record: any) => {
    //   if (record.newRow) return <></>;
    //   const claimIds = text.split(',').map((id: any) => ({ id, name: id }));
    //   return <SelectInput options={claimIds} enableDefaultPlaceholder={true} enableLabel={false} />;
    // },
  },
  {
    title: 'Amount',
    dataIndex: 'amountFillable',
    key: 'amountFillable',
    render: (text: any, record: any) => (
      <InputField
        placeholder="Amount"
        enableLabel={false}
        name="amount"
        type="number"
        onChange={(e: any) => handleRowDataChange(record?.patientVisitCptId, e)}
        disabled={record?.claim === 0}
      />
    ),
  },
  {
    title: 'Allowed',
    dataIndex: 'allowedAmount',
    key: 'allowedAmount',
    render: (text: any, record: any) => {
      return record.newRow ? (
        <></>
      ) : (
        <InputField
          placeholder="Allowed Amount"
          enableLabel={false}
          name="allowedAmount"
          type="number"
          onChange={(e: any) => handleRowDataChange(record?.patientVisitCptId, e)}
          disabled={record?.claim === 0}
        />
      );
    },
  },
];

const dummyDataSource = {
  patient: {
    patientId: 218,
    patientName: 'Vishal Hank',
  },
  whoPaid: [
    {
      value: 'Patient',
      label: 'Vishal Hank',
    },
    {
      value: 'Primary',
      label: 'Medicare',
    },
    {
      value: 'Secondary',
      label: 'Health Care',
    },
    {
      value: 'Tertiary',
      label: 'Divine Life',
    },
  ],
  items: [
    {
      dos: '2024-01-01',
      cpt: '99213',
      units: 1,
      amount: 150,
      modifier: '25',
      balance: 50,
      claimId: '13425,12123,43434',
    },
    {
      dos: '2024-02-01',
      cpt: '99354',
      units: 2,
      amount: 300,
      modifier: '',
      balance: 100,
      claimId: '89321',
    },
  ],
};

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const BulkPayments: React.FC<BulkPaymentsPropsInterface> = ({ batchData }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    fetchBulkPaymentListStatus,
    fetchBulkPaymentListData,
    fetchBulkPaymentListLoading,
    fetchBulkPaymentListError,
  } = useAppSelector(getBulkPaymentsListSelector);
  const { fetchSystemBillingCodeListData, fetchSystemBillingCodeListStatus } = useAppSelector(
    getSystemBillingCodeListSelector
  );
  const { addBulkLinePaymentsStatus } = useAppSelector(addBulkLinePaymentsSelector);

  const [patientSearchData, setPatientSearchData] = useState<any>({});
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [paymentsData, setPaymentsData] = useState<any>({ paymentDetails: [] });
  const [insurancesList, setInsuranceList] = useState<string[]>([]);
  const [whoPaidOptions, setWhoPaidOptions] = useState<{ id: number; name: string }[]>([]);
  const [billingCodeList, setBillingCodeList] = useState<any[]>([]);
  const [patientData, setPatientData] = useState<any>({});
  const [selectedPatientVisitCptId, setSelectedPatientVisitCptId] = useState<number | null>(null);
  const [visiblePatientSearchModal, setVisiblePatientSearchModal] = useState<boolean>(false);
  const [visibleAddDescriptionModal, setVisibleAddDescriptionModal] = useState<boolean>(false);
  const [previousPatientId, setPreviousPatientId] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [isVisitsEmpty, setIsVisitsEmpty] = useState<boolean>(false);
  const [hasCompleteCptDetail, setHasCompleteCptDetail] = useState<boolean>(false);
  const [rowDescription, setRowDescription] = useState<string>('');
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    fetchSystemBillingCodes();
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (fetchBulkPaymentListStatus === 'SUCCESS') {
      const isCasesEmpty = fetchBulkPaymentListData?.cases?.length === 0;
      // const isAllVisitCptListsEmpty = fetchBulkPaymentListData?.cases?.every(
      //   (caseData: any) => caseData?.visitCptList?.length === 0
      // );

      if (isCasesEmpty) {
        setIsVisitsEmpty(true);
        setAlertObj({
          color: 'success',
          message: 'No visits available for this patient!',
          error: true,
        });
        setVisibleAlert(true);
        setTimeout(() => {
          setAlertObj(initialAlertState);
          setVisibleAlert(false);
        }, 5000);
      } else {
        setDataSource(fetchBulkPaymentListData?.cases);
        setPatientData(fetchBulkPaymentListData?.patient);

        const formattedOptions = formatWhoPaidOptions(fetchBulkPaymentListData?.whoPaid);
        setWhoPaidOptions(formattedOptions);

        const insuranceArray = createInsuranceList(fetchBulkPaymentListData?.whoPaid);
        setInsuranceList(insuranceArray);
      }
      setRowDescription('');
      setIsLoaded(true);
      dispatch(clearFetchBulkPaymentsListResponse());
    } else if (fetchBulkPaymentListStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: fetchBulkPaymentListError?.message ?? 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchBulkPaymentsListResponse());
    }
  }, [fetchBulkPaymentListStatus]);

  useEffect(() => {
    if (addBulkLinePaymentsStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Submitted Successfully!',
        error: true,
      });
      setVisibleAlert(true);
      fetchBulkPaymentsList(patientData?.id);
      setPaymentsData({ paymentDetails: [] });
      setIsLoaded(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearAddBulkLinePaymentsResponse());
    } else if (addBulkLinePaymentsStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearAddBulkLinePaymentsResponse());
    }
  }, [addBulkLinePaymentsStatus]);

  useEffect(() => {
    if (fetchSystemBillingCodeListStatus === 'SUCCESS') {
      const formattedBillingCodes = formatBillingCodes(fetchSystemBillingCodeListData);
      setBillingCodeList(formattedBillingCodes);
    }
  }, [fetchSystemBillingCodeListStatus]);

  useEffect(() => {
    if (patientSearchData?.patientId && patientSearchData.patientId !== previousPatientId) {
      setPatientSearchData((prev: any) => ({
        ...prev,
        patientName: '',
        patientId: patientSearchData.patientId,
      }));
    }
  }, [patientSearchData.patientId, previousPatientId]);

  useEffect(() => {
    const completeDetailExists = paymentsData?.paymentDetails?.some((caseData: any) =>
      caseData?.cptDetails?.some(isCptDetailsComplete)
    );

    setHasCompleteCptDetail(completeDetailExists);
  }, [paymentsData]);

  const createInsuranceList = (whoPaid: any[] = []) =>
    whoPaid
      .filter((item) => item.type)
      .map((item) => `${item.type.charAt(0).toUpperCase() + item.type.slice(1).toLowerCase()}: ${item.name}`);

  const findCaseData = (patientVisitCptId: any) => {
    return (
      dataSource
        ?.flatMap((caseData) => caseData?.visitCptList?.map((visit: any) => ({ caseId: caseData.caseId, visit })))
        .find((entry) => entry.visit.patientVisitCptId === patientVisitCptId) || null
    );
  };

  const handleUpdatePaymentsData = (patientVisitCptId: any, updateData: { [key: string]: any }) => {
    const caseDetails = findCaseData(patientVisitCptId);

    // console.log('Case Details', caseDetails);
    if (caseDetails) {
      setPaymentsData((prev: any) => {
        const updatedPaymentDetails = [...prev.paymentDetails];
        let caseIndex = updatedPaymentDetails.findIndex((detail: any) => detail.caseId === caseDetails.caseId);

        if (caseIndex === -1) {
          updatedPaymentDetails.push({
            caseId: caseDetails?.caseId,
            cptDetails: [],
          });
          caseIndex = updatedPaymentDetails?.length - 1;
        }

        const visitIndex = updatedPaymentDetails[caseIndex].cptDetails.findIndex(
          (visit: any) => visit?.patientVisitCptId === patientVisitCptId
        );

        if (visitIndex === -1) {
          updatedPaymentDetails[caseIndex].cptDetails.push({
            patientVisitCptId: patientVisitCptId,
            claimId: caseDetails?.visit?.claim ?? null,
            checkNumber: batchData?.checkNumber ?? null,
            ...updateData,
          });
        } else {
          updatedPaymentDetails[caseIndex].cptDetails[visitIndex] = {
            ...updatedPaymentDetails[caseIndex].cptDetails[visitIndex],
            ...updateData,
          };
        }

        return { ...prev, paymentDetails: updatedPaymentDetails };
      });
    }
  };

  const isCptDetailsComplete = (cptDetail: any) => {
    if (!cptDetail) return false;

    const requiredFields = [
      'patientVisitCptId',
      // 'claimId',
      // 'checkNumber',
      'billingCode',
      'billingCodeType',
      'amount',
      'allowedAmount',
    ];
    return requiredFields.every(
      (field) => cptDetail[field] !== undefined && cptDetail[field] !== null && cptDetail[field] !== ''
    );
  };

  const findRowDescription = (patientVisitCptId: any) =>
    paymentsData?.paymentDetails
      ?.flatMap((caseDetail: any) => caseDetail?.cptDetails)
      .find((cpt: any) => cpt.patientVisitCptId === patientVisitCptId)?.description || null;

  const handleWhoPaidChange = (e: any) => {
    setPaymentsData((prev: any) => ({
      ...prev,
      whoPaid: e.target.value,
    }));
  };

  const fetchBulkPaymentsList = (patientId: number) => {
    dispatch(getBulkPaymentsListRequest({ patientId }));
  };

  const onSearch = () => {
    setPaymentsData({ paymentDetails: [] });
    setIsLoaded(false);
    setIsVisitsEmpty(false);
    fetchBulkPaymentsList(patientSearchData?.patientId);
  };

  const convertBeforeSubmit = (paymentData: any) => {
    const obj = {
      ...paymentData,
      patientId: parseInt(paymentData?.patientId, 10),
      whoPaid: parseInt(paymentData?.whoPaid, 10),
      paymentDetails: paymentData?.paymentDetails?.map((caseData: any) => ({
        ...caseData,
        caseId: parseInt(caseData?.caseId, 10),
        cptDetails: caseData?.cptDetails?.map((cptDetail: any) => ({
          ...cptDetail,
          patientVisitCptId: parseInt(cptDetail.patientVisitCptId, 10),
          claimId: cptDetail?.claimId ? parseInt(cptDetail.claimId, 10) : null,
          billingCode: parseInt(cptDetail.billingCode, 10),
          billingCodeType: parseInt(cptDetail.billingCodeType, 10),
          amount: Number(parseFloat(cptDetail.amount).toFixed(2)),
          allowedAmount: Number(parseFloat(cptDetail.allowedAmount).toFixed(2)),
        })),
      })),
    };

    return obj;
  };

  const onSubmit = () => {
    if (!paymentsData?.whoPaid) {
      setAlertObj({
        color: 'failure',
        message: 'Who Paid is required!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 4000);
      return;
    }

    const filteredPaymentsData = paymentsData?.paymentDetails
      .map((caseData: any) => ({
        ...caseData,
        cptDetails: caseData?.cptDetails?.filter(isCptDetailsComplete),
      }))
      .filter((caseData: any) => caseData?.cptDetails?.length > 0);

    // console.log('Filtered', filteredPaymentsData);

    const paymentDataObj = {
      patientId: patientData?.id,
      whoPaid: paymentsData?.whoPaid,
      paymentDetails: filteredPaymentsData,
    };

    const payload = {
      batchId: batchData?.id,
      paymentsData: convertBeforeSubmit(paymentDataObj),
    };

    dispatch(addBulkLinePaymentsRequest(payload));
  };

  const fetchSystemBillingCodes = () => {
    dispatch(getSystemBillingCodeListRequest());
  };

  const formatBillingCodes = (codes: any[]) => {
    return codes.map((code) => ({
      id: code.id,
      name: `${code.code ? code.code + ' - ' : ''}${code.name}`,
    }));
  };

  const handleRowDataChange = (patientVisitCptId: any, e: any) => {
    const { name, value } = e.target;
    handleUpdatePaymentsData(patientVisitCptId, { [name]: value });
  };

  const handleBillingCodeChange = (patientVisitCptId: any, selectedValue: string) => {
    const selectedCode = fetchSystemBillingCodeListData?.find((code: any) => code.id === Number(selectedValue));

    handleUpdatePaymentsData(patientVisitCptId, {
      billingCodeType: selectedCode ? selectedCode?.type : '',
      billingCode: selectedCode ? selectedCode?.id : '',
    });
  };

  const onChangeId = (e: any) => {
    setPatientSearchData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectPatient = (data: any) => {
    setPatientSearchData((prev: any) => ({ ...prev, patientName: data?.name, patientId: data?.id }));
    setPreviousPatientId(data?.id);
  };

  const handleAddRow = (event: any, rowIndex: any, caseIndex: any) => {
    event.preventDefault();

    setDataSource((prev: any) => {
      const updatedCases = [...prev];

      const updatedVisitCptList = [...updatedCases[caseIndex].visitCptList];
      const rowPatientVisitCptId = updatedVisitCptList[rowIndex].patientVisitCptId;

      const newRow = {
        newRow: true,
        patientVisitCptId: rowPatientVisitCptId,
      };

      updatedVisitCptList.splice(rowIndex + 1, 0, newRow);

      updatedCases[caseIndex] = {
        ...updatedCases[caseIndex],
        visitCptList: updatedVisitCptList,
      };

      return updatedCases;
    });
  };

  const handleClickNote = (event: any, rowIndex: any, caseIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[caseIndex]?.visitCptList[rowIndex];
    const description = findRowDescription(dataSet?.patientVisitCptId);
    setRowDescription(description || '');
    setSelectedPatientVisitCptId(dataSet?.patientVisitCptId);
    setVisibleAddDescriptionModal(true);
  };

  const handleAddDescription = (data: any) => {
    handleUpdatePaymentsData(selectedPatientVisitCptId, { description: data });
    setAlertObj({
      color: 'success',
      message: 'Description Added!',
      error: false,
    });
    setVisibleAlert(true);
    setTimeout(() => {
      setAlertObj(initialAlertState);
      setVisibleAlert(false);
      setSelectedPatientVisitCptId(null);
      setVisibleAddDescriptionModal(false);
    }, 1000);
  };

  const handlePatientClick = () => {
    navigate(`/patients?id=${patientData?.id}`, {
      state: { fromBulkPayments: true },
    });
  };

  const formatWhoPaidOptions = (whoPaid: any[] = []) =>
    whoPaid.map((item) => {
      const formattedName = item.type
        ? `${item?.name} (${item.type.charAt(0).toUpperCase() + item.type.slice(1).toLowerCase()})`
        : item?.name;
      return { id: item?.id, name: formattedName };
    });

  const handleDescriptionModalClose = () => {
    setSelectedPatientVisitCptId(null);
    setVisibleAddDescriptionModal(false);
    setRowDescription('');
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  // console.log('dataSource', dataSource);
  // console.log('Billing codes', billingCodeList);
  // console.log('paymentData', paymentsData);
  // console.log('rowDescription', rowDescription);
  return (
    <>
      {visibleAlert && alertObj?.error && (
        <CommonAlert
          color={alertObj?.color}
          message={alertObj?.message}
          onClose={handleAlertClose}
          alertClassName="w-1/3"
        />
      )}
      <div className="grid md:grid-cols-5 gap-4 mt-6">
        <InputField label="Batch ID" name="id" disabled defaultValue={batchData?.id ?? undefined} />
        <InputField
          label="Payment Date"
          name="checkDate"
          disabled
          defaultValue={
            batchData?.checkDate && moment(batchData.checkDate).isValid()
              ? moment(batchData.checkDate).format('MM/DD/YYYY')
              : undefined
          }
        />
        <InputField
          label="Check Number"
          name="checkNumber"
          disabled
          defaultValue={batchData?.checkNumber ?? undefined}
        />
        <InputField
          label="Description"
          name="description"
          disabled
          defaultValue={batchData?.description ?? undefined}
        />
        <InputField
          label="UnApplied"
          name="unAppliedAmount"
          disabled
          defaultValue={formatCurrency(batchData?.unAppliedAmount ?? 0)}
        />
      </div>
      <div className="grid md:grid-cols-5 gap-4 mt-6 items-end">
        <div className="flex items-end">
          {' '}
          <InputField
            label=""
            name="patientName"
            placeholder="Patient Name"
            disabled
            value={patientSearchData?.patientName ?? undefined}
          />
          <CustomSearchButton onClick={() => setVisiblePatientSearchModal(true)} />
        </div>

        <InputField
          label=""
          name="patientId"
          placeholder="Patient ID"
          value={patientSearchData?.patientId ?? ''}
          onChange={onChangeId}
        />
        <PrimaryButton type="button" label="Search" style={{ height: '40px', maxWidth: '100px' }} onClick={onSearch} />
      </div>

      {fetchBulkPaymentListLoading ? (
        <Spinner />
      ) : (
        isLoaded &&
        !isVisitsEmpty && (
          <div className="mt-14">
            <div className="text-black">
              Patient Name:{' '}
              <span className="text-customRed no-underline cursor-pointer" onClick={handlePatientClick}>
                {patientData?.name ?? ''}
              </span>
            </div>
            <div className="grid md:grid-cols-4 gap-4 mt-2 mb-8 items-end">
              <SelectInput
                defaultPlaceholder="Select Who Paid"
                options={whoPaidOptions}
                name="whoPaid"
                value={paymentsData?.whoPaid ?? undefined}
                onChange={handleWhoPaidChange}
              />
              <div className="col-span-1 flex justify-start space-x-2">
                <PrimaryButton type="button" label="Reload" style={{ height: '40px', maxWidth: '100px' }} />
                <CommonButton
                  type="button"
                  label="Submit"
                  buttonType="primary"
                  style={{ height: '40px', maxWidth: '100px' }}
                  onClick={onSubmit}
                  disabled={!hasCompleteCptDetail}
                />
              </div>
              <div className="col-span-2"></div>
            </div>

            <BulkPaymentsCustomTable
              columns={columns(billingCodeList, handleBillingCodeChange, handleRowDataChange)}
              cases={dataSource}
              insurances={insurancesList}
              onHandleNote={handleClickNote}
              // onHandleAdd={handleAddRow}
            />
          </div>
        )
      )}

      {visiblePatientSearchModal && (
        <PatientSearchModal
          patientSearchModalOpen={visiblePatientSearchModal}
          setOpenPatientSearchModal={setVisiblePatientSearchModal}
          selectPatient={handleSelectPatient}
        />
      )}

      {visibleAddDescriptionModal && (
        <AddDescriptionModal
          addDescriptionModalOpen={visibleAddDescriptionModal}
          onCloseModal={handleDescriptionModalClose}
          value={rowDescription}
          onSubmit={handleAddDescription}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          handleAlertClose={handleAlertClose}
        />
      )}
    </>
  );
};
