import { authenticatedRequest } from '../../utils/commonAxios';

export const getDashboardCardDataAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/dashboard/cards`, 'get', {});
};

export const getDashboardPerformanceTableDataAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/dashboard/performances`, 'get', {});
};

export const getDashboardProviderTableDataAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/dashboard/providers`, 'get', {});
};

export const getDashboardPlotDataAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/dashboard/chart`, 'get', {});
};
